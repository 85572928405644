import { SignupPage } from '@webapp/ui';
import { Suspense, lazy, useEffect, useState } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import {
  LeadFormPage,
  IdleDialog,
  StickyNote,
  BoardView,
  ManagerPinModal,
  TakePhotoV2,
  TelehealthModal,
  GlobalPinLock,
} from '@webapp/webapp/ui-composites';
import { CustomTokenAuth } from '@webapp/ui-composites';
import './app.module.scss';
import { useFlagsmith } from 'flagsmith-react';
import { observer } from 'mobx-react-lite';
import { useStores } from '@webapp/state-models';
import { useIdleTimer } from 'react-idle-timer';
import * as Sentry from '@sentry/react';
import { BaseLayout } from '@webapp/webapp/layouts';
import { Center, Spinner } from '@chakra-ui/react';
import RequireAuth from './utils/require-auth';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
/* #region Page Imports */
const ImportAppointmentsPage = lazy(
  () =>
    import(
      '../../../../libs/webapp/pages/src/lib/ImportAppointmentsPage/ImportAppointmentsPage'
    )
);

const ImportFlowsheetPage = lazy(
  () =>
    import(
      '../../../../libs/webapp/pages/src/lib/ImportFlowsheetPage/ImportFlowsheetPage'
    )
);

const ImportInvoicesPage = lazy(
  () =>
    import(
      '../../../../libs/webapp/pages/src/lib/ImportInvoicesPage/ImportInvoicesPage'
    )
);

const ImportPaymentsPage = lazy(
  () =>
    import(
      '../../../../libs/webapp/pages/src/lib/ImportPaymentsPage/ImportPaymentsPage'
    )
);

const ImportServicesPage = lazy(
  () =>
    import(
      '../../../../libs/webapp/pages/src/lib/ServiceImportPage/ServiceImportPage'
    )
);

const ImportClinicalNotesPage = lazy(
  () =>
    import(
      '../../../../libs/webapp/pages/src/lib/ImportClinicalNotesPage/ImportClinicalNotesPage'
    )
);

const ImportPatientsPage = lazy(
  () =>
    import(
      '../../../../libs/webapp/pages/src/lib/ImportPatientsPage/ImportPatientsPage'
    )
);

const LabPage = lazy(
  () => import('../../../../libs/webapp/pages/src/lib/labs-page')
);

const LeadListPage = lazy(
  () =>
    import('../../../../libs/webapp/pages/src/lib/LeadListPage/LeadListPage')
);

const OnboardUserPage = lazy(
  () =>
    import(
      '../../../../libs/webapp/pages/src/lib/onboard-user-page/onboard-user-page'
    )
);

const PrescriptionsPageV2 = lazy(
  () =>
    import(
      '../../../../libs/webapp/pages/src/lib/PrescriptionsPageV2/PrescriptionsPageV2'
    )
);

const PreviewConsentPage = lazy(
  () =>
    import(
      '../../../../libs/webapp/pages/src/lib/PreviewConsentPage/PreviewConsentPage'
    )
);

const ProviderDailyRecapPage = lazy(
  () =>
    import(
      '../../../../libs/webapp/pages/src/lib/ProviderDailyRecapPage/ProviderDailyRecapPage'
    )
);

const Sandbox = lazy(
  () => import('../../../../libs/webapp/pages/src/lib/Sandbox/Sandbox')
);

const TelehealthEmbedPage = lazy(
  () =>
    import(
      '../../../../libs/webapp/pages/src/lib/telehealth-embed-page/telehealth-embed-page'
    )
);

const CreatePatientPage = lazy(
  () =>
    import(
      '../../../../libs/webapp/pages/src/lib/create-patient-page/create-patient-page'
    )
);

const AppointmentPage = lazy(
  () =>
    import(
      '../../../../libs/webapp/pages/src/lib/AppointmentPage/AppointmentPage'
    )
);

const PhotoPage = lazy(
  () => import('../../../../libs/webapp/pages/src/lib/photo-page/photo-page')
);

const OnboardPage = lazy(
  () =>
    import('../../../../libs/webapp/pages/src/lib/onboard-page/onboard-page')
);

const VerifyMagicLinkPage = lazy(
  () =>
    import(
      '../../../../libs/webapp/pages/src/lib/verify-magic-link-page/verify-magic-link-page'
    )
);

const ReportsPage = lazy(
  () =>
    import('../../../../libs/webapp/pages/src/lib/reports-page/reports-page')
);

const Patients = lazy(
  () => import('../../../../libs/webapp/pages/src/lib/patients/patients')
);

const CompleteFormPage = lazy(
  () => import('../../../../libs/webapp/pages/src/lib/complete-form-page')
);

const CreateAudiencePage = lazy(
  () =>
    import(
      '../../../../libs/webapp/pages/src/lib/create-audience-page/create-audience-page'
    )
);

const EditAudiencePageV2 = lazy(
  () =>
    import(
      '../../../../libs/webapp/pages/src/lib/EditAudiencePageV2/EditAudiencePageV2'
    )
);

const EditAudiencePageShim = lazy(
  () =>
    import(
      '../../../../libs/webapp/pages/src/lib/EditAudiencePageShim/EditAudiencePageShim'
    )
);

const EmailMarketingPage = lazy(
  () =>
    import(
      '../../../../libs/webapp/pages/src/lib/email-marketing-page/email-marketing-page'
    )
);

const ViewDocumentPage = lazy(
  () => import('../../../../libs/webapp/pages/src/lib/view-document-page')
);

const SocialMediaPage = lazy(
  () => import('../../../../libs/webapp/pages/src/lib/social-media-page')
);

const SettingsPage = lazy(
  () =>
    import('../../../../libs/webapp/pages/src/lib/settings-page/settings-page')
);

const MediaListPage = lazy(
  () =>
    import(
      '../../../../libs/webapp/pages/src/lib/media-list-page/media-list-page'
    )
);

const LoginPage = lazy(
  () => import('../../../../libs/webapp/pages/src/lib/login-page/login-page')
);

const SchedulePage = lazy(
  () =>
    import('../../../../libs/webapp/pages/src/lib/schedule-page/schedule-page')
);

const TimesheetPage = lazy(
  () =>
    import('../../../../libs/webapp/pages/src/lib/TimesheetPage/TimesheetPage')
);

const PatientPage = lazy(
  () => import('../../../../libs/webapp/pages/src/lib/patient/patient')
);

const CompleteConsentPage = lazy(
  () => import('../../../../libs/webapp/pages/src/lib/complete-consent-page')
);

const GenerateSurveyJSConsentPDFPage = lazy(
  () =>
    import(
      '../../../../libs/webapp/pages/src/lib/GenerateSurveyJSConsentPDF/GenerateSurveyJSConsentPDF'
    )
);

const ConversationsPage = lazy(
  () =>
    import(
      '../../../../libs/webapp/pages/src/lib/conversations-page/conversations-page'
    )
);

const ViewCompletedConsentPage = lazy(
  () =>
    import('../../../../libs/webapp/pages/src/lib/view-completed-consent-page')
);

const EmailEditPage = lazy(
  () =>
    import(
      '../../../../libs/webapp/pages/src/lib/email-edit-page/email-edit-page'
    )
);

const EmailTemplateEditPage = lazy(
  () =>
    import(
      '../../../../libs/webapp/pages/src/lib/email-template-edit-page/email-template-edit-page'
    )
);

const CreateOrEditEmailCampaignPage = lazy(
  () =>
    import(
      '../../../../libs/webapp/pages/src/lib/CreateOrEditEmailCampaignPage/CreateOrEditEmailCampaignPage'
    )
);

const EmailCampaignAnalyticsPage = lazy(
  () =>
    import(
      '../../../../libs/webapp/pages/src/lib/EmailCampaignAnalyticsPage/EmailCampaignAnalyticsPage'
    )
);

const SMSCampaignEditPage = lazy(
  () =>
    import(
      '../../../../libs/webapp/pages/src/lib/CreateOrEditSMSCampaignPage/CreateOrEditSMSCampaignPage'
    )
);

const CreateInvoicePageDeprecated = lazy(
  () =>
    import(
      '../../../../libs/webapp/pages/src/lib/create-invoice-page-deprecated/CreateInvoicePageDeprecated'
    )
);

const CreateInvoicePage = lazy(
  () =>
    import(
      '../../../../libs/webapp/pages/src/lib/create-invoice-page/CreateInvoicePage'
    )
);

const CreateBeforeAndAfterPage = lazy(
  () =>
    import(
      '../../../../libs/webapp/pages/src/lib/create-before-and-after-page/create-before-and-after-page'
    )
);

const CreateBeforeAndAfterPhotoPage = lazy(
  () =>
    import(
      '../../../../libs/webapp/pages/src/lib/create-before-and-after-photo-page'
    )
);

const ReceiptPage = lazy(
  () =>
    import('../../../../libs/webapp/pages/src/lib/receipt-page/receipt-page')
);

const PayInvoicePage = lazy(
  () =>
    import(
      '../../../../libs/webapp/pages/src/lib/PayInvoicePage/PayInvoicePage'
    )
);

const PrescriptionsPage = lazy(
  () => import('../../../../libs/webapp/pages/src/lib/prescriptions-page')
);

const HomePage = lazy(
  () => import('../../../../libs/webapp/pages/src/lib/home-page/home-page')
);

const ReviewsPage = lazy(
  () => import('../../../../libs/webapp/pages/src/lib/ReviewsPage/ReviewsPage')
);

const InvoicePage = lazy(
  () => import('../../../../libs/webapp/pages/src/lib/invoice-page/InvoicePage')
);

const InvoicesPage = lazy(
  () =>
    import('../../../../libs/webapp/pages/src/lib/invoices-page/InvoicesPage')
);

const TasksPage = lazy(
  () => import('../../../../libs/webapp/pages/src/lib/TasksPage/TasksPage')
);

const SecurityBlockPage = lazy(
  () =>
    import(
      '../../../../libs/webapp/pages/src/lib/SecurityBlockPage/SecurityBlockPage'
    )
);

/* #endregion */

export const AuthWrapper = observer(() => {
  const { identify, hasFeature } = useFlagsmith();
  const { user, workspace, logout, ui } = useStores();
  const [showIdleDialog, setShowIdleDialog] = useState<boolean>(false);

  useEffect(() => {
    if (user && workspace)
      identify(user?.id, {
        userId: user?.id,
        workspaceId: workspace?.id,
        role: user?.userWorkspaces[0]?.role || 'none',
        flags: workspace?.flags,
      });
  }, [user, workspace]);

  const onIdle = () => {
    if (!user) return;
    // Close Modal Prompt
    // Do some idle action like log out your user
    setShowIdleDialog(false);
    logout();
  };

  const onPrompt = () => {
    if (!user) return;
    // Fire a Modal Prompt
    setShowIdleDialog(true);
  };

  useIdleTimer({
    onIdle,
    onPrompt,
    crossTab: true,
    syncTimers: 1000 * 60,
    timeout: 1000 * 60 * 60 * 4, // 4 hours
    promptTimeout: 1000 * 60, // 1 minute
  });

  if (user?.userWorkspaces[0]?.role === 'bookkeeper') {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <SentryRoutes>
          <Route
            element={
              <RequireAuth>
                <BaseLayout>
                  <Outlet />
                </BaseLayout>
              </RequireAuth>
            }
          >
            <Route path="/*" element={<ReportsPage />} />
            <Route path="/settings/*" element={<SettingsPage />} />
          </Route>
        </SentryRoutes>
      </Suspense>
    );
  }

  return (
    <>
      <SentryRoutes>
        {/* Authenticated Routes */}
        <Route
          element={
            <RequireAuth>
              <Outlet />
            </RequireAuth>
          }
        >
          {/* Base Layout Scope */}
          <Route
            element={
              <BaseLayout>
                <Outlet />
              </BaseLayout>
            }
          >
            <Route path="/" element={<HomePage />} />
            <Route
              path="/appointments/:appointmentId"
              element={<AppointmentPage />}
            />
            <Route path="/media" element={<MediaListPage />} />
            <Route path="/media/photo/:id" element={<PhotoPage />} />
            <Route
              path="/media/new-before-after/*"
              element={
                hasFeature('media_archive:creative_editor_before_and_after') ? (
                  <CreateBeforeAndAfterPhotoPage />
                ) : (
                  <CreateBeforeAndAfterPage />
                )
              }
            />
            <Route
              path="/media/side-by-side/*"
              element={<CreateBeforeAndAfterPage />}
            />
            <Route path="/prescriptions" element={<PrescriptionsPage />} />
            <Route
              path="/prescriptions/:patientId"
              element={<PrescriptionsPageV2 />}
            />
            {hasFeature('patients') && (
              <>
                <Route path="/patients" element={<Patients />} />
                <Route
                  path="/patients/create"
                  element={<CreatePatientPage />}
                />
                <Route
                  path="/patients/:patientId/*"
                  element={<PatientPage />}
                />
              </>
            )}
            {hasFeature('invoices') && (
              <>
                <Route element={<InvoicesPage />} path="/invoices" />
                <Route element={<InvoicePage />} path="/invoices/:invoiceId" />

                <Route
                  element={<PayInvoicePage />}
                  path="/invoices/:invoiceId/pay"
                />

                <Route
                  element={<CreateInvoicePage />}
                  path="/patients/:patientId/invoices/create"
                />
                <Route
                  element={
                    hasFeature('invoices:create_invoice_v2') ? (
                      <CreateInvoicePage />
                    ) : (
                      <CreateInvoicePageDeprecated />
                    )
                  }
                  path="/invoices/create"
                />
              </>
            )}
            {hasFeature('online_reputation') && (
              <Route path="/reviews/*" element={<ReviewsPage />} />
            )}
            {hasFeature('lead_list') && (
              <Route path="/leads" element={<LeadListPage />} />
            )}
            {hasFeature('labs') && <Route path="/labs" element={<LabPage />} />}
            {hasFeature('timesheet') && (
              <Route path="/timesheet" element={<TimesheetPage />} />
            )}
            <Route path="/settings/*" element={<SettingsPage />} />
            <Route path="/social-media/*" element={<SocialMediaPage />} />
            <Route
              path="/email-marketing/templates"
              element={<EmailMarketingPage activeTab="templates" />}
            />
            <Route
              path="/email-marketing/audiences"
              element={<EmailMarketingPage activeTab="audiences" />}
            />
            <Route
              path="/email-marketing/analytics"
              element={<EmailMarketingPage activeTab="analytics" />}
            />
            <Route
              path="/email-marketing/*"
              element={<EmailMarketingPage activeTab="emails" />}
            />
            {hasFeature('marketing:audiences_v2') ? (
              <Route
                path="/email-marketing/audience/create"
                element={<EditAudiencePageV2 />}
              />
            ) : (
              <Route
                path="/email-marketing/audience/create"
                element={<CreateAudiencePage />}
              />
            )}
            <Route
              path="/email-marketing/audience/:audienceId"
              element={<EditAudiencePageShim />}
            />
            <Route
              path="/email-marketing/email-template/create"
              element={<CreateOrEditEmailCampaignPage />}
            />
            <Route
              path="/email-marketing/email-template/:emailTemplateId"
              element={
                <RequireAuth>
                  <CreateOrEditEmailCampaignPage />
                </RequireAuth>
              }
            />
            <Route
              path="/email-marketing/email-template/:emailTemplateId/analytics"
              element={
                <RequireAuth>
                  <EmailCampaignAnalyticsPage />
                </RequireAuth>
              }
            />
            <Route
              path="/email-marketing/sms-campaign"
              element={<EmailMarketingPage activeTab="sms" />}
            />
            <Route
              path="/email-marketing/sms-campaign/:smsCampaignId"
              element={<SMSCampaignEditPage />}
            />
            <Route
              path="/email-marketing/sms-campaign/create"
              element={<SMSCampaignEditPage />}
            />
            <Route
              path="/email-marketing/email-template-base/create"
              element={<EmailTemplateEditPage />}
            />
            <Route
              path="/email-marketing/email-template-base/:emailTemplateId"
              element={<EmailTemplateEditPage />}
            />
            <Route path="/conversations" element={<ConversationsPage />} />
            <Route
              path="/telehealth/:telehealthMeetingId"
              element={<TelehealthEmbedPage />}
            />
            <Route path="/tasks" element={<TasksPage />} />
            <Route path="/patient-import" element={<ImportPatientsPage />} />
            <Route path="/service-import" element={<ImportServicesPage />} />
            <Route
              path="/appointment-import"
              element={<ImportAppointmentsPage />}
            />
            <Route path="/flowsheet-import" element={<ImportFlowsheetPage />} />
            <Route path="/invoice-import" element={<ImportInvoicesPage />} />
            <Route path="/payment-import" element={<ImportPaymentsPage />} />
            <Route path="/note-import" element={<ImportClinicalNotesPage />} />
            <Route
              path="/provider/daily-recap"
              element={<ProviderDailyRecapPage />}
            />
            <Route path="/reports/*" element={<ReportsPage />} />
          </Route>
          {/* Custom Layout Scope (Custom drawer layout implementation, filling out forms, etc) */}
          <Route
            element={
              <Suspense
                fallback={
                  <Center h="100vh">
                    <Spinner size="lg" colorScheme={'teal'} />
                  </Center>
                }
              >
                <Outlet />
              </Suspense>
            }
          >
            {hasFeature('schedule') && (
              <Route path="/schedule" element={<SchedulePage />} />
            )}
            {hasFeature('patients') && (
              <>
                <Route
                  path="/patients/:patientId/appointments/:appointmentId/forms/:formId"
                  element={<CompleteFormPage />}
                />
                <Route
                  path="/patients/:patientId/documents/:documentId"
                  element={<ViewDocumentPage />}
                />
                <Route
                  path="/patients/:patientId/appointments/:appointmentId/consents/:consentId"
                  element={<CompleteConsentPage />}
                />
                <Route
                  path="/patients/:patientId/patient-consents/:patientConsentId"
                  element={<ViewCompletedConsentPage />}
                />
                <Route
                  path="/consents/:consentId/preview"
                  element={<PreviewConsentPage />}
                />
              </>
            )}
          </Route>
        </Route>

        {/* This seems like it can be removed ?? */}
        <Route path="/boardview" element={<BoardView />} />

        {/* CustomTokenAuth Routes */}
        <Route
          element={
            <CustomTokenAuth>
              <Outlet />
            </CustomTokenAuth>
          }
        >
          {hasFeature('patients') && (
            <>
              <Route
                path="/patients/:patientId/appointments/:appointmentId/forms/:formId/pdf"
                element={<CompleteFormPage printing={true} />}
              />
              <Route
                path="/patients/:patientId/appointments/:appointmentId/consents/:consentId/save-pdf"
                element={<GenerateSurveyJSConsentPDFPage />}
              />
            </>
          )}
          <Route
            element={<ReceiptPage />}
            path="/invoices/:invoiceId/receipt"
          />
        </Route>
        {/* Unauthenticated Routes */}
        <Route>
          <Route path="/v" element={<VerifyMagicLinkPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="security-block" element={<SecurityBlockPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/onboard" element={<OnboardPage />} />
          <Route path="/onboard-user" element={<OnboardUserPage />} />
          <Route path="/leadform/:formId" element={<LeadFormPage />} />
          <Route path="/take-photo-test" element={<TakePhotoV2 />} />
          {process.env.VITE_ENV === 'development' && (
            <Route path="/sandbox/*" element={<Sandbox />} />
          )}
        </Route>
      </SentryRoutes>
      <StickyNote />
      <TelehealthModal />
      <ManagerPinModal />
      <IdleDialog
        isOpen={showIdleDialog}
        onClose={() => setShowIdleDialog(false)}
      />
      <GlobalPinLock />
    </>
  );
});

export default AuthWrapper;
