import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetRewardsByProgramIdQueryVariables = Types.Exact<{
  programId: Types.Scalars['uuid'];
}>;


export type GetRewardsByProgramIdQuery = { __typename?: 'query_root', reward: Array<{ __typename?: 'reward', id: any, pointCost: number, cashValue: number }> };


export const GetRewardsByProgramIdDocument = gql`
    query getRewardsByProgramId($programId: uuid!) {
  reward(where: {rewardProgramId: {_eq: $programId}}) {
    id
    pointCost
    cashValue
  }
}
    `;

/**
 * __useGetRewardsByProgramIdQuery__
 *
 * To run a query within a React component, call `useGetRewardsByProgramIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRewardsByProgramIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRewardsByProgramIdQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useGetRewardsByProgramIdQuery(baseOptions: Apollo.QueryHookOptions<GetRewardsByProgramIdQuery, GetRewardsByProgramIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRewardsByProgramIdQuery, GetRewardsByProgramIdQueryVariables>(GetRewardsByProgramIdDocument, options);
      }
export function useGetRewardsByProgramIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRewardsByProgramIdQuery, GetRewardsByProgramIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRewardsByProgramIdQuery, GetRewardsByProgramIdQueryVariables>(GetRewardsByProgramIdDocument, options);
        }
export type GetRewardsByProgramIdQueryHookResult = ReturnType<typeof useGetRewardsByProgramIdQuery>;
export type GetRewardsByProgramIdLazyQueryHookResult = ReturnType<typeof useGetRewardsByProgramIdLazyQuery>;
export type GetRewardsByProgramIdQueryResult = Apollo.QueryResult<GetRewardsByProgramIdQuery, GetRewardsByProgramIdQueryVariables>;