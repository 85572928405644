import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { TimesheetAdminFieldsFragmentDoc } from '../../fragments/timesheetAdminFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type AdminUpdateTimesheetEntryMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  set: Types.Timesheet_Set_Input;
}>;


export type AdminUpdateTimesheetEntryMutation = { __typename?: 'mutation_root', update_timesheet_by_pk?: { __typename?: 'timesheet', adminNote?: string | null | undefined, approvedBy?: any | null | undefined, clockInTime: any, clockOutTime?: any | null | undefined, flaggedForReview: boolean, id: any, isApproved: boolean, userNote?: string | null | undefined, approvingUser?: { __typename?: 'user', id: any, firstName: string, lastName: string } | null | undefined, user: { __typename?: 'user', id: any, firstName: string, lastName: string } } | null | undefined };


export const AdminUpdateTimesheetEntryDocument = gql`
    mutation AdminUpdateTimesheetEntry($id: uuid!, $set: timesheet_set_input!) {
  update_timesheet_by_pk(pk_columns: {id: $id}, _set: $set) {
    ...TimesheetAdminFields
  }
}
    ${TimesheetAdminFieldsFragmentDoc}`;
export type AdminUpdateTimesheetEntryMutationFn = Apollo.MutationFunction<AdminUpdateTimesheetEntryMutation, AdminUpdateTimesheetEntryMutationVariables>;

/**
 * __useAdminUpdateTimesheetEntryMutation__
 *
 * To run a mutation, you first call `useAdminUpdateTimesheetEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateTimesheetEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateTimesheetEntryMutation, { data, loading, error }] = useAdminUpdateTimesheetEntryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useAdminUpdateTimesheetEntryMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpdateTimesheetEntryMutation, AdminUpdateTimesheetEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUpdateTimesheetEntryMutation, AdminUpdateTimesheetEntryMutationVariables>(AdminUpdateTimesheetEntryDocument, options);
      }
export type AdminUpdateTimesheetEntryMutationHookResult = ReturnType<typeof useAdminUpdateTimesheetEntryMutation>;
export type AdminUpdateTimesheetEntryMutationResult = Apollo.MutationResult<AdminUpdateTimesheetEntryMutation>;
export type AdminUpdateTimesheetEntryMutationOptions = Apollo.BaseMutationOptions<AdminUpdateTimesheetEntryMutation, AdminUpdateTimesheetEntryMutationVariables>;