import { getApps, initializeApp } from 'firebase/app';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const NX_FIREBASE_CONFIG: string =
  import.meta.env.VITE_FIREBASE_CONFIG?.replace(/'/g, '') as string;

const firebaseConfig = JSON.parse(NX_FIREBASE_CONFIG);

const apps = getApps();

export const firebaseApp = !apps.length
  ? initializeApp(
      import.meta.env.VITE_ENV === 'development' ||
        import.meta.env.VITE_ENV === 'test'
        ? {
            apiKey: 'test',
            authDomain: 'test.firebaseapp.com',
            projectId: 'test',
          }
        : firebaseConfig
    )
  : apps[0];
export const firebaseAuth = getAuth(firebaseApp);

if (
  import.meta.env.VITE_ENV === 'development' ||
  import.meta.env.VITE_ENV === 'test'
) {
  try {
    connectAuthEmulator(
      firebaseAuth,
      import.meta.env.VITE_FIREBASE_AUTH_EMULATOR || 'http://localhost:9099',
      {
        disableWarnings: true,
      }
    );
  } catch (e) {
    console.error(e);
  }
}
