import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetExternalBookingWorkspaceConfigurationQueryVariables = Types.Exact<{
  workspaceId: Types.Scalars['uuid'];
}>;


export type GetExternalBookingWorkspaceConfigurationQuery = { __typename?: 'query_root', workspaceConfiguration: Array<{ __typename?: 'workspaceConfiguration', noShowActive?: boolean | null | undefined, noShowFee?: number | null | undefined }>, rewardProgram: Array<{ __typename?: 'rewardProgram', id: any, configuration: any }> };


export const GetExternalBookingWorkspaceConfigurationDocument = gql`
    query GetExternalBookingWorkspaceConfiguration($workspaceId: uuid!) {
  workspaceConfiguration(where: {workspaceId: {_eq: $workspaceId}}) {
    noShowActive
    noShowFee
  }
  rewardProgram(where: {workspaceId: {_eq: $workspaceId}}) {
    id
    configuration
  }
}
    `;

/**
 * __useGetExternalBookingWorkspaceConfigurationQuery__
 *
 * To run a query within a React component, call `useGetExternalBookingWorkspaceConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExternalBookingWorkspaceConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExternalBookingWorkspaceConfigurationQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useGetExternalBookingWorkspaceConfigurationQuery(baseOptions: Apollo.QueryHookOptions<GetExternalBookingWorkspaceConfigurationQuery, GetExternalBookingWorkspaceConfigurationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExternalBookingWorkspaceConfigurationQuery, GetExternalBookingWorkspaceConfigurationQueryVariables>(GetExternalBookingWorkspaceConfigurationDocument, options);
      }
export function useGetExternalBookingWorkspaceConfigurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExternalBookingWorkspaceConfigurationQuery, GetExternalBookingWorkspaceConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExternalBookingWorkspaceConfigurationQuery, GetExternalBookingWorkspaceConfigurationQueryVariables>(GetExternalBookingWorkspaceConfigurationDocument, options);
        }
export type GetExternalBookingWorkspaceConfigurationQueryHookResult = ReturnType<typeof useGetExternalBookingWorkspaceConfigurationQuery>;
export type GetExternalBookingWorkspaceConfigurationLazyQueryHookResult = ReturnType<typeof useGetExternalBookingWorkspaceConfigurationLazyQuery>;
export type GetExternalBookingWorkspaceConfigurationQueryResult = Apollo.QueryResult<GetExternalBookingWorkspaceConfigurationQuery, GetExternalBookingWorkspaceConfigurationQueryVariables>;