import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetRewardProgramRedeemablesByPatientIdQueryVariables = Types.Exact<{
  patientId: Types.Scalars['uuid'];
}>;


export type GetRewardProgramRedeemablesByPatientIdQuery = { __typename?: 'query_root', patient?: { __typename?: 'patient', rewardProgramEnrollments: Array<{ __typename?: 'rewardProgramEnrollment', id: any, rewardProgramBalance?: { __typename?: 'rewardProgramBalance', balance?: any | null | undefined } | null | undefined, rewardProgram: { __typename?: 'rewardProgram', rewards: Array<{ __typename?: 'reward', id: any, pointCost: number, cashValue: number }>, rewardCoupons: Array<{ __typename?: 'rewardCoupon', id: any, pointCost: number, coupon: { __typename?: 'coupon', id: any, name: string } }> } }> } | null | undefined };


export const GetRewardProgramRedeemablesByPatientIdDocument = gql`
    query getRewardProgramRedeemablesByPatientId($patientId: uuid!) {
  patient: patient_by_pk(id: $patientId) {
    rewardProgramEnrollments {
      id
      rewardProgramBalance {
        balance
      }
      rewardProgram {
        rewards {
          id
          pointCost
          cashValue
        }
        rewardCoupons {
          id
          pointCost
          coupon {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetRewardProgramRedeemablesByPatientIdQuery__
 *
 * To run a query within a React component, call `useGetRewardProgramRedeemablesByPatientIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRewardProgramRedeemablesByPatientIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRewardProgramRedeemablesByPatientIdQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useGetRewardProgramRedeemablesByPatientIdQuery(baseOptions: Apollo.QueryHookOptions<GetRewardProgramRedeemablesByPatientIdQuery, GetRewardProgramRedeemablesByPatientIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRewardProgramRedeemablesByPatientIdQuery, GetRewardProgramRedeemablesByPatientIdQueryVariables>(GetRewardProgramRedeemablesByPatientIdDocument, options);
      }
export function useGetRewardProgramRedeemablesByPatientIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRewardProgramRedeemablesByPatientIdQuery, GetRewardProgramRedeemablesByPatientIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRewardProgramRedeemablesByPatientIdQuery, GetRewardProgramRedeemablesByPatientIdQueryVariables>(GetRewardProgramRedeemablesByPatientIdDocument, options);
        }
export type GetRewardProgramRedeemablesByPatientIdQueryHookResult = ReturnType<typeof useGetRewardProgramRedeemablesByPatientIdQuery>;
export type GetRewardProgramRedeemablesByPatientIdLazyQueryHookResult = ReturnType<typeof useGetRewardProgramRedeemablesByPatientIdLazyQuery>;
export type GetRewardProgramRedeemablesByPatientIdQueryResult = Apollo.QueryResult<GetRewardProgramRedeemablesByPatientIdQuery, GetRewardProgramRedeemablesByPatientIdQueryVariables>;