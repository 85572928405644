import React, { useEffect, useState } from 'react';
import { RRule } from 'rrule';
import { HStack, Stack } from '@chakra-ui/react';
import { SelectOption } from '@webapp/types';
import { FormInputV2 } from '@webapp/ui';
import { configuredDayjs as dayjs } from '@webapp/util-time';
import { useStores } from '@webapp/state-models';

interface RecurringRulesFormProps {
  rRuleString?: string | null;
  recurring?: boolean | null;
  recurringEndDate?: string | null;
  recurringStartDate: string | null;
  onEndDateChange: (endDate: string) => void;
  onIsRecurringChange: (isRecurring: boolean) => void;
  onSetRRule: (rule: string) => void;
}

const rRuleFreqMap = {
  day: RRule.DAILY,
  week: RRule.WEEKLY,
  month: RRule.MONTHLY,
};

const rRuleFreqOptionsMap = {
  [RRule.DAILY]: { label: 'Day', value: 'day' },
  [RRule.WEEKLY]: { label: 'Week', value: 'week' },
  [RRule.MONTHLY]: { label: 'Month', value: 'month' },
};

const RecurringRulesForm = ({
  rRuleString,
  recurring,
  recurringEndDate,
  recurringStartDate,
  onEndDateChange,
  onIsRecurringChange,
  onSetRRule,
}: RecurringRulesFormProps) => {
  const { draftAppointment } = useStores();
  const [isRecurring, setIsRecurring] = useState<boolean>(recurring || false);
  const [initialRecurring] = useState<boolean>(recurring || false);

  useEffect(() => {
    if (isRecurring && !initialRecurring) {
      draftAppointment?.setUpdatedToRecurring(true);
    }
  }, [isRecurring]);

  const frequency = rRuleString
    ? RRule.parseString(rRuleString).freq
    : RRule.DAILY;
  const [repeatType, setRepeatType] = useState<SelectOption>(
    rRuleFreqOptionsMap[frequency as keyof typeof rRuleFreqOptionsMap]
  );
  const [endDate, setEndDate] = useState<string>(
    recurringEndDate || dayjs().format('YYYY-MM-DD')
  );

  const rRuleOptions = rRuleString
    ? RRule.parseString(rRuleString)
    : {
        freq: RRule.DAILY,
        until: dayjs(endDate).toDate(),
      };
  const [rRule, setRRule] = useState<object>(rRuleOptions);

  useEffect(() => {
    if (isRecurring) {
      draftAppointment?.setRecurringStartDate(
        draftAppointment.startTime
          ? dayjs(draftAppointment.startTime).format('YYYY-MM-DD')
          : dayjs().format('YYYY-MM-DD')
      );
    } else {
      draftAppointment?.unsetRecurrence();
    }
  }, [isRecurring]);

  useEffect(() => {
    if (isRecurring) {
      const rule = new RRule(rRule);
      onSetRRule(rule.toString());
    }
  }, [isRecurring, rRule]);

  const handleIsRecurring = () => {
    setIsRecurring(!isRecurring);
    onIsRecurringChange(!isRecurring);
  };

  const handleRepeatyType = (value: SelectOption | null) => {
    setRepeatType(value);
    setRRule({
      ...rRule,
      freq: rRuleFreqMap[value?.value as keyof typeof rRuleFreqMap],
    });
  };

  const handleEndDate = (newDate: string) => {
    setEndDate(newDate);
    setRRule({
      ...rRule,
      until: dayjs(newDate).toDate(),
    });
    onEndDateChange(newDate);
  };

  return (
    <Stack>
      <FormInputV2
        testId="formInput-isRecurring"
        uncontrolled
        label="Recurring appointment"
        description="Set up recurring appointment"
        name="isRecurring"
        type="checkbox"
        onChange={handleIsRecurring}
        value={!!isRecurring}
      />

      {isRecurring && (
        <Stack>
          <HStack>
            <FormInputV2
              id="formInput-repeatType"
              uncontrolled
              label="Repeats every"
              name="repeatType"
              onChange={(newValue) => {
                handleRepeatyType(newValue);
              }}
              options={[
                { label: 'Day', value: 'day' },
                { label: 'Week', value: 'week' },
                { label: 'Month', value: 'month' },
              ]}
              type="select"
              value={repeatType}
            />

            <FormInputV2
              testId="formInput-endDate"
              uncontrolled
              label="Repeats until"
              name="endDate"
              onChange={(newDate) => {
                handleEndDate(newDate);
              }}
              type="date"
              value={endDate}
            />
          </HStack>
        </Stack>
      )}
    </Stack>
  );
};

export default RecurringRulesForm;
