/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import {
  chakra,
  Button,
  Flex,
  HStack,
  Stack,
  Tab,
  TabList,
  Box,
  TabPanel,
  TabPanels,
  Tabs,
  useBreakpointValue,
  StackProps,
  Skeleton,
} from '@chakra-ui/react';
import {
  useInsertNoteMutation,
  useUpdateNoteByPkMutation,
  Note_Insert_Input,
  useGetOneNoteQuery,
  useInsertNoteSignatureMutation,
} from '@webapp/graphql';
import { useGenerateMergeTagContext } from '@webapp/hooks';
import {
  HookForm,
  SmartTextEditorSize,
  SmartTextEditor,
  FormInputV2,
  SmartTextEditorToolbarVersion,
} from '@webapp/ui';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { SubmitHandler } from 'react-hook-form';
import toast from 'react-hot-toast';
import debounce from 'lodash.debounce';
import { FaExpandArrowsAlt } from 'react-icons/fa';
import { useFlagsmith } from 'flagsmith-react';
import { useStores } from '@webapp/state-models';
import { NoteFieldsFragment } from 'libs/graphql/src/fragments/noteFields.fragment.generated';
import { NoteLockStatus } from '../NoteLockStatus/NoteLockStatus';
import SaveAndSign from '../save-and-sign/save-and-sign';
import NoteHeader from '../NoteHeader/NoteHeader';
import NoteAuditLog from '../NoteAuditLog/NoteAuditLog';
import ClinicalNoteTypeahead from '../NoteHeader/TypeAhead';

/* eslint-disable-next-line */
export interface NoteModalFormProps {
  appointmentId?: string;
  patientId?: string;
  noteId?: string;
  parentNoteId?: string;
  toolbarVersion?: SmartTextEditorToolbarVersion;
  height?: string;
  onClose?: () => void;
  onComplete?: () => void;
  onCreateNote?: (note: NoteFieldsFragment) => void;
  refetchQueries?: string[];
  hideHeader?: boolean;
  hideFooter?: boolean;
  containerStyle?: StackProps;
  innerContainerStyle?: StackProps;
}

export interface NoteModalHandle {
  popout: () => void;
}

export const NoteModalForm = forwardRef<NoteModalHandle, NoteModalFormProps>(
  (
    {
      appointmentId,
      patientId,
      noteId: noteIdProp,
      onComplete,
      onClose,
      refetchQueries,
      toolbarVersion = SmartTextEditorToolbarVersion.DEFAULT,
      height = '50vh',
      hideHeader = false,
      hideFooter = false,
      parentNoteId,
      onCreateNote,
      containerStyle = {},
      innerContainerStyle = {},
    }: NoteModalFormProps,
    ref
  ) => {
    const formRef = useRef<any>(null);
    const noteId = useRef<string | undefined>(noteIdProp);
    const textEditorRef = useRef<any>(null);
    const { hasFeature } = useFlagsmith();
    const [updateNoteByPk] = useUpdateNoteByPkMutation();

    const [assignedToProviderId, setAssignedToProviderId] = useState<
      string | undefined
    >();
    const {
      workspace,
      ui: { showStickyNote },
    } = useStores();
    const isMobile = useBreakpointValue({ base: true, md: false });

    const { data: noteData, loading: noteLoading } = useGetOneNoteQuery({
      variables: {
        id: noteId?.current,
      },
      skip: !noteId?.current,
    });

    const { data: parentNoteData, loading: parentNoteLoading } =
      useGetOneNoteQuery({
        variables: {
          id: parentNoteId,
        },
        skip: !parentNoteId,
      });

    const { context: mergeTagContext, contextReady } =
      useGenerateMergeTagContext({
        patientId,
        appointmentId,
        note: noteData?.note_by_pk,
      });

    const [insert, { loading: insertingNote }] = useInsertNoteMutation({
      onCompleted: (data) => {
        noteId.current = data.insert_note?.returning[0].id;
        if (data.insert_note?.returning?.[0])
          onCreateNote?.(data.insert_note?.returning[0]);
      },
      refetchQueries: [
        ...(refetchQueries ?? []),
        'ListNotes',
        'AggregateNotes',
      ],
    });

    const [update, { loading: updatingNote }] = useUpdateNoteByPkMutation({
      onError: (e) => {
        toast.error(e.message);
      },
      refetchQueries: [
        ...(refetchQueries ?? []),
        'ListNotes',
        'AggregateNotes',
      ],
    });
    const [insertNoteSignature] = useInsertNoteSignatureMutation();

    const note = noteData?.note_by_pk;
    const parentNote = parentNoteData?.note_by_pk;

    function popout() {
      onSubmit(formRef?.current?.getValues()).then(({ data }) => {
        let _noteId = note?.id;

        if (!_noteId) {
          if (data?.insert_note?.returning?.[0]?.id) {
            _noteId = data?.insert_note?.returning?.[0]?.id;
          }
          if (data?.update_note_by_pk?.id) {
            _noteId = data?.update_note_by_pk?.id;
          }
        }
        // grab any existsing content
        showStickyNote(
          patientId as string,
          textEditorRef?.current?.saveContent(),
          appointmentId,
          _noteId
        );
        onClose?.();
      });
    }

    useImperativeHandle(ref, () => ({
      popout: () => popout(),
      getContent: () => textEditorRef?.current?.saveContent(),
      saveNote: () => onSubmit(formRef?.current?.getValues()),
    }));

    function handleNoteTitleChange(newTitle: string) {
      updateNoteByPk({
        variables: {
          id: note?.id,
          set: {
            title: newTitle,
          },
        },
      }).then(() => {
        toast.success('Note title updated');
      });
    }

    const debouncedSave = debounce(async () => {
      if (!formRef.current) return;
      onSubmit(formRef.current.getValues());
    }, 15000);

    useEffect(() => {
      if (!note && !noteId?.current) {
        onSubmit(formRef.current.getValues());
      }
    }, [note, noteId?.current]);

    const onSubmit: SubmitHandler<Note_Insert_Input & { signatureId: string }> =
      useCallback(
        async (fields) => {
          const message = textEditorRef?.current?.saveContent() ?? '';
          const editorJSON = textEditorRef?.current?.getEditorJSON() ?? {};

          if (note || noteId?.current) {
            // When updating, insert a new note signature if one doesn't exist for the currently submitted signature
            const signatureAlreadyExists = note?.noteSignatures?.some(
              ({ signature }) => signature?.id === fields.signatureId
            );

            if (fields.signatureId && !signatureAlreadyExists) {
              await insertNoteSignature({
                variables: {
                  noteSignature: {
                    noteId: note?.id || noteId?.current,
                    signatureId: fields.signatureId,
                  },
                },
              });
            }

            return update({
              variables: {
                id: note?.id || noteId?.current,
                set: {
                  isInternal: Boolean(fields.isInternal),
                  ...(fields.signatureId && { isLocked: true }),
                  message,
                  isGoodFaithExam: Boolean(fields.isGoodFaithExam),
                  ...(assignedToProviderId && {
                    assignedToProviderId,
                  }),
                  editorJSON,
                },
              },
            });
          }

          return insert({
            variables: {
              notes: [
                {
                  ...(appointmentId && {
                    appointmentId,
                  }),
                  message,
                  patientId,
                  ...(parentNoteId && {
                    parentNoteId,
                  }),
                  ...(fields.signatureId && {
                    noteSignatures: {
                      data: [
                        {
                          signatureId: fields.signatureId,
                        },
                      ],
                    },
                  }),
                  isInternal: Boolean(fields.isInternal),
                  isGoodFaithExam: Boolean(fields.isGoodFaithExam),
                  ...(assignedToProviderId && {
                    assignedToProviderId,
                  }),
                  editorJSON,
                },
              ],
            },
          });
        },
        [
          note,
          note?.id,
          noteId,
          update,
          insert,
          insertNoteSignature,
          textEditorRef,
          assignedToProviderId,
          appointmentId,
          parentNoteId,
        ]
      );

    if (noteIdProp && !note) {
      return <Skeleton height={height} />;
    }

    return (
      <HookForm
        formRef={formRef}
        defaultValues={{
          message: note?.message,
          isInternal: Boolean(note?.isInternal),
          isGoodFaithExam: Boolean(note?.isGoodFaithExam),
        }}
      >
        {({ control }) => (
          <Stack spacing={4} p={0} m={0}>
            {!hideHeader && (
              <NoteHeader
                patientId={patientId}
                appointmentId={appointmentId}
                note={note}
                parentNote={parentNote}
                onAssignProvider={setAssignedToProviderId}
                saving={insertingNote || updatingNote}
              />
            )}
            <Tabs isLazy>
              <TabList>
                <Tab>Editor</Tab>
                <Tab>Audit Log</Tab>
              </TabList>

              <TabPanels>
                <TabPanel p={0} m={0}>
                  <Stack maxW={{ base: '93vw', md: '93vw' }} p={0} m={0}>
                    {hideHeader && (
                      <Box mb={2} w={'full'}>
                        <ClinicalNoteTypeahead
                          defaultValue={note?.title}
                          onSaveNoteTitle={handleNoteTitleChange}
                        />
                      </Box>
                    )}
                    {contextReady && (
                      <SmartTextEditor
                        key={`${note?.id}-${note?.isLocked}-${Object.keys(
                          mergeTagContext ?? {}
                        ).join('-')}`}
                        onUpdate={debouncedSave}
                        ref={textEditorRef}
                        editable={!note?.isLocked}
                        content={note?.message}
                        patientId={patientId}
                        appointmentId={appointmentId}
                        workspaceId={workspace?.id}
                        size={SmartTextEditorSize.SMALL}
                        hideSave
                        height={height}
                        creatingTemplate={false}
                        toolbarVersion={toolbarVersion}
                        mergeTagContext={mergeTagContext}
                        containerStyle={containerStyle}
                        innerContainerStyle={innerContainerStyle}
                      />
                    )}
                  </Stack>
                </TabPanel>
                <TabPanel>
                  <NoteAuditLog noteId={note?.id} />
                </TabPanel>
              </TabPanels>
            </Tabs>

            <chakra.div
              display="grid"
              gridColumnGap="10px"
              gridTemplateColumns="repeat(2, max-content)"
            >
              <FormInputV2
                control={control as any}
                description="Internal Note"
                name="isInternal"
                type="checkbox"
              />
              {hasFeature('good-faith-exams') && (
                <FormInputV2
                  control={control as any}
                  description="Good Faith Exam"
                  name="isGoodFaithExam"
                  type="checkbox"
                />
              )}
            </chakra.div>
            {!hideFooter && (
              <Flex
                w="full"
                flexDirection={{ base: 'column', md: 'row' }}
                justifyContent={{ base: 'flex-start', md: 'space-between' }}
                alignItems={{ base: 'stretch', md: 'center' }}
                mt={4}
                gap={4}
              >
                <Button
                  flexShrink={0}
                  onClick={onClose}
                  w={{ base: 'full', md: 'auto' }}
                >
                  Cancel
                </Button>
                <Stack
                  direction={{ base: 'column', md: 'row' }}
                  spacing={{ base: 4, md: 2 }}
                  w={{ base: 'full', md: 'auto' }}
                  justifyContent="right"
                  flexGrow={1}
                >
                  <NoteLockStatus
                    note={note}
                    saveNote={() => {
                      onSubmit(formRef.current.getValues());
                      onComplete?.();
                      onClose?.();
                    }}
                  />
                  {!isMobile && (
                    <Button
                      colorScheme="teal"
                      variant={'outline'}
                      leftIcon={<FaExpandArrowsAlt />}
                      onClick={popout}
                      w={{ base: 'full', md: 'auto' }}
                    >
                      Pop out
                    </Button>
                  )}
                  {!note?.isLocked && (
                    <SaveAndSign
                      onSigned={() => {
                        onSubmit(formRef.current.getValues());
                        onComplete?.();
                        onClose?.();
                      }}
                    />
                  )}
                </Stack>
              </Flex>
            )}
          </Stack>
        )}
      </HookForm>
    );
  }
);

export default NoteModalForm;
