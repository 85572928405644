import { Stack, Box, chakra } from '@chakra-ui/react';
import { LocationFieldsFragment } from '@webapp/graphql';
import { Appointment, useStores } from '@webapp/state-models';
import { ObjectOption } from '@webapp/types';
import { FormInput } from '@webapp/ui';
import { useLoadProviders, useLoadLocations } from '@webapp/webapp/hooks';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { configuredDayjs as dayjs } from '@webapp/util-time';
import RecurringRulesForm from '../RecurringRulesForm/RecurringRulesForm';
import { RRule } from 'rrule';

const CreateOtherAppointment = observer(() => {
  const { calendarCurrentEventDate, calendarTimeZone, draftAppointment } =
    useStores();
  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      timerange: {
        date:
          draftAppointment?.isRecurring &&
          draftAppointment?.date !== calendarCurrentEventDate
            ? calendarCurrentEventDate
            : dayjs(draftAppointment?.startTime)
                .tz(calendarTimeZone)
                .format('YYYY-MM-DD'),
        start: draftAppointment?.startTime,
        end: draftAppointment?.endTime,
      },
      color: draftAppointment?.colorOverride || '#ff8303',
      note: draftAppointment?.note,
      providerIds: draftAppointment?.internalAppointmentProviderIds as string[],
    },
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
  });

  const providerSelectProps = {
    loadOptions: useLoadProviders,
    defaultOptions:
      draftAppointment?.internalAppointmentProviders.map(
        (internalProvider) => ({
          label: `${internalProvider?.firstName} ${internalProvider?.lastName}`,
          value: internalProvider?.id,
          object: internalProvider,
        })
      ) ?? [],
    canCreate: false,
  };

  const locationSelectProps = {
    loadOptions: useLoadLocations,
    defaultOption: draftAppointment?.location
      ? {
          object: draftAppointment?.location,
          value: draftAppointment?.location?.id,
          label: draftAppointment?.location?.name,
        }
      : null,

    canCreate: false,
  };

  const { providerIds, timerange, location, note, color } = methods.watch() as {
    providerIds: string[];
    location: ObjectOption<LocationFieldsFragment>;
    note: string;
    color: string;
    timerange: { date: string; start: string; end: string };
  };

  useEffect(() => {
    if (providerIds !== undefined) {
      draftAppointment?.setInternalAppointmentProviderIds(providerIds);
    }
    if (timerange.date && timerange.start) {
      const date = dayjs(timerange.date).format('YYYY-MM-DD');
      const startTime = dayjs
        .tz(
          `${date} ${timerange.start}`,
          'YYYY-MM-DD hh:mm A',
          calendarTimeZone
        )
        .toISOString();
      draftAppointment?.setStartTime(startTime);
    }
    if (timerange.date && timerange.end) {
      const date = dayjs(timerange.date).format('YYYY-MM-DD');
      const endTime = dayjs
        .tz(`${date} ${timerange.end}`, 'YYYY-MM-DD hh:mm A', calendarTimeZone)
        .toISOString();
      draftAppointment?.setEndTime(endTime);
    }
    if (location?.value !== undefined) {
      draftAppointment?.setLocation(location.object);
    }
    if (note !== undefined) {
      draftAppointment?.setNote(note);
    }
    if (color !== undefined) {
      draftAppointment?.setColor(color);
    }
  }, [providerIds, color, timerange, location, note]);

  return (
    <Box h={'full'} w={'full'}>
      <FormProvider {...methods}>
        <chakra.form>
          <Stack spacing={4}>
            <FormInput
              label="Day & Time"
              name="timerange"
              timezone={calendarTimeZone}
              type="date-timerange-picker"
              datePickerProps={{
                stepMinute: 15,
              }}
            />

            <RecurringRulesForm
              onEndDateChange={
                draftAppointment?.setRecurringEndDate || (() => {})
              }
              onIsRecurringChange={
                draftAppointment?.setIsRecurring || (() => {})
              }
              onSetRRule={draftAppointment?.setRecurringRule || (() => {})}
              recurring={draftAppointment?.isRecurring}
              recurringEndDate={
                draftAppointment?.recurringEndDate || draftAppointment?.endTime
              }
              recurringStartDate={
                draftAppointment?.recurringStartDate ||
                dayjs().tz(calendarTimeZone).format()
              }
              rRuleString={draftAppointment?.recurringRule}
            />

            <Box data-testid="other-appointment-provider-select">
              <FormInput
                isEditable={true}
                label="Providers"
                multiSelectProps={providerSelectProps}
                name="providerIds"
                placeholder="Select providers"
                type="multiselect"
              />
            </Box>

            <Box data-testid="other-appointment-location-select">
              <FormInput
                isEditable={true}
                label="Location"
                selectProps={locationSelectProps}
                name="location"
                placeholder="Select location"
                type="async-select"
              />
            </Box>
            <FormInput label="Customize Color" name="color" type="color" />

            <FormInput
              label="Notes"
              name="note"
              placeholder="Notes"
              type="textarea"
            />
          </Stack>
        </chakra.form>
      </FormProvider>
    </Box>
  );
});

export default CreateOtherAppointment;
