import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  ModalHeader,
  ModalOverlay,
  Image,
  SimpleGrid,
  HStack,
  Heading,
  Center,
} from '@chakra-ui/react';
import { Photo, PhotoModel, useStores } from '@webapp/state-models';
import { PhotoFieldsFragment } from '@webapp/graphql';
import PatientPhotos from '../patient-photos/patient-photos';

/* eslint-disable-next-line */
export interface SelectPhotoOrTemplateProps {
  patientId?: string;
  setSelectedTemplate: (photo: PhotoModel) => void;
  removeAssetPrefix?: boolean;
}

export function SelectPhotoOrTemplate({
  patientId,
  setSelectedTemplate,
  removeAssetPrefix = false,
}: SelectPhotoOrTemplateProps) {
  const { workspace } = useStores();
  const templates = [
    `/assets/ManFace.png`,
    `/assets/WomanFace.png`,
    `/assets/face-left.jpeg`,
    `/assets/face-forward.jpeg`,
    `/assets/botox-chart.png`,
    `/assets/face-right.jpeg`,
    `/assets/ManNudeFront.png`,
    `/assets/ManNudeSide.png`,
    `/assets/ManNudeBack.png`,
    `/assets/ManNudeNoArm.png`,
    `/assets/WomanNudeFront.png`,
    `/assets/WomanNudeSide.png`,
    `/assets/WomanNudeBack.png`,
    `/assets/WomanNudeSideNoArm.png`,
    `/assets/Abdomen - Man.jpg`,
    `/assets/Abdomen - Woman.jpg`,
    `/assets/Breast - Woman.jpg`,
    `/assets/Buttock - Man.jpg`,
    `/assets/Buttock - Woman.jpg`,
    `/assets/Chest - Man.jpg`,
    `/assets/Hair Loss Zones.jpg`,
    `/assets/Leg-Lateral-Left.jpg`,
    `/assets/Legs-Anterior.jpg`,
    `/assets/Legs-Lateral- Right.jpg`,
    `/assets/Legs-Posterior.jpg`,
    `/assets/Legs.jpg`,
    `/assets/Pelvis, Genitalia - Man.jpg`,
    `/assets/Pelvis, Genitalia - Women.jpg`,
    `/assets/Scalp2.jpg`,
    `/assets/Vulva.jpg`,
  ].map((url, index) =>
    PhotoModel.create({
      id: index.toString(),
      workspaceId: workspace?.id as string,
      file: {
        filePath: url,
        url,
      },
    })
  );

  return (
    <Tabs w={'full'} variant={'prospyr'}>
      <TabList>
        <Tab>Templates</Tab>
        {patientId && <Tab>Patient Photos</Tab>}
      </TabList>

      <TabPanels>
        <TabPanel>
          <SimpleGrid
            minChildWidth={'20vw'}
            w={'full'}
            spacing={4}
            maxH={'85vh'}
            overflowY={'auto'}
          >
            {templates.map((photo) => (
              <Image
                cursor={'pointer'}
                key={photo.id}
                objectFit="contain"
                h={'30vh'}
                w={'30vh'}
                rounded="lg"
                shadow="base"
                src={photo?.file?.url}
                onClick={(): void => {
                  const p = JSON.parse(JSON.stringify(photo));
                  if (removeAssetPrefix) {
                    p.file.url = p.file?.url.replace('/assets', '');
                  }

                  setSelectedTemplate(PhotoModel.create(p));
                }}
              />
            ))}
          </SimpleGrid>
        </TabPanel>
        {patientId && (
          <TabPanel>
            <Center w={'full'} h={'85vh'}>
              <PatientPhotos
                patientId={patientId}
                onClickPhoto={(photo: PhotoFieldsFragment): void => {
                  setSelectedTemplate(PhotoModel.create(photo));
                }}
              />
            </Center>
          </TabPanel>
        )}
      </TabPanels>
    </Tabs>
  );
}

export default SelectPhotoOrTemplate;
