import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { TimesheetUserFieldsFragmentDoc } from '../../fragments/timesheetUserFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateTimesheetEntryMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  clockOutTime?: Types.Maybe<Types.Scalars['timestamptz']>;
  userNote?: Types.Maybe<Types.Scalars['String']>;
}>;


export type UpdateTimesheetEntryMutation = { __typename?: 'mutation_root', update_timesheet_by_pk?: { __typename?: 'timesheet', approvedBy?: any | null | undefined, clockInTime: any, clockOutTime?: any | null | undefined, id: any, isApproved: boolean, userId: any, userNote?: string | null | undefined } | null | undefined };


export const UpdateTimesheetEntryDocument = gql`
    mutation UpdateTimesheetEntry($id: uuid!, $clockOutTime: timestamptz, $userNote: String) {
  update_timesheet_by_pk(
    pk_columns: {id: $id}
    _set: {clockOutTime: $clockOutTime, userNote: $userNote}
  ) {
    ...TimesheetUserFields
  }
}
    ${TimesheetUserFieldsFragmentDoc}`;
export type UpdateTimesheetEntryMutationFn = Apollo.MutationFunction<UpdateTimesheetEntryMutation, UpdateTimesheetEntryMutationVariables>;

/**
 * __useUpdateTimesheetEntryMutation__
 *
 * To run a mutation, you first call `useUpdateTimesheetEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimesheetEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimesheetEntryMutation, { data, loading, error }] = useUpdateTimesheetEntryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      clockOutTime: // value for 'clockOutTime'
 *      userNote: // value for 'userNote'
 *   },
 * });
 */
export function useUpdateTimesheetEntryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTimesheetEntryMutation, UpdateTimesheetEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTimesheetEntryMutation, UpdateTimesheetEntryMutationVariables>(UpdateTimesheetEntryDocument, options);
      }
export type UpdateTimesheetEntryMutationHookResult = ReturnType<typeof useUpdateTimesheetEntryMutation>;
export type UpdateTimesheetEntryMutationResult = Apollo.MutationResult<UpdateTimesheetEntryMutation>;
export type UpdateTimesheetEntryMutationOptions = Apollo.BaseMutationOptions<UpdateTimesheetEntryMutation, UpdateTimesheetEntryMutationVariables>;