import {
  AppointmentFieldsFullFragment,
  PatientFieldsFragment,
  useGetAppointmentQuery,
  useGetOnePatientQuery,
  NoteFieldsFragment,
} from '@webapp/graphql';
import { usePatientAttributes } from '@webapp/webapp/hooks';
import { calculateBmi, heightToMeters, lbsToKilograms } from '@webapp/utils';
import dayjs from 'dayjs';
import { useState, useEffect } from 'react';
import { usePatientFlowsheets } from '../usePatientFlowsheets/usePatientFlowsheets';

function heightToInches(height: string): number {
  const [feet, inches] = height.split("'");
  return parseInt(feet, 10) * 12 + parseInt(inches, 10);
}

export interface MergeTagContext {
  appointmentDate?: string;
  author?: string;
  additionalAllergyInfo?: string;
  additionalFamilyHistoryInfo?: string;
  additionalMedicalHistoryInfo?: string;
  additionalSymptomInfo?: string;
  alcoholConsumption?: string;
  allergiesAdhesiveTape?: string;
  allergiesBeeStings?: string;
  allergiesIodine?: string;
  allergiesLatex?: string;
  allergiesLidocane?: string;
  allergiesMedicine?: string;
  otherRelevantAllergies?: string;
  anesthesiaComplications?: string;
  cancerDetails?: string;
  cigaretteSmokingStatus?: string;
  dob?: string;
  email?: string;
  familyHistory?: string[];
  firstName?: string;
  gender?: string;
  height?: string;
  historyOfColdSores?: string;
  howDidYouFindUs?: string;
  lastMenstrualCycle?: string;
  lastName?: string;
  medicalHistory?: string[];
  medicationDosage?: any[];
  nursing?: string;
  otherSmokingStatus?: string;
  pastSurgeries?: any[];
  phoneNumber?: string;
  planningPregnancy?: string;
  preferredPharmacy?: string;
  preferredPharmacyPhoneNumber?: string;
  pregnant?: string;
  problematicAnesthesia?: string;
  race?: string;
  recreationalDrugUse?: string[];
  sex?: string;
  symptoms?: string[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UseGenerateMergeTagContext {
  context: MergeTagContext | undefined;
  contextReady: boolean;
}

export interface UseMergeTagContextProps {
  patientId?: string;
  appointmentId?: string;
  note?: NoteFieldsFragment;
}

function buildContext(
  patient: PatientFieldsFragment | undefined | null,
  appointment: AppointmentFieldsFullFragment | undefined | null,
  getValue: (key: string) => string | undefined,
  flowsheets: any,
  note: NoteFieldsFragment | undefined | null
) {
  const context: MergeTagContext = {
    ...(patient && { ...patient?.attributes?.attributes }),
  };
  try {
    if (patient) {
      let { height, weight, sex, gender } = patient.attributes;
      if (!height) {
        height = patient.attributes?.attributes?.height?.entities?.[0]?.value;
      }

      if (!weight) {
        weight = patient.attributes?.attributes?.weight?.entities?.[0]?.value;
      }

      if (!sex) {
        sex = patient.attributes?.attributes?.sex?.entities?.[0]?.value;
      }

      if (!gender) {
        gender = patient.attributes?.attributes?.gender?.entities?.[0]?.value;
      }

      context.firstName = patient.attributes.firstName ?? patient.firstName;
      context.lastName = patient.attributes.lastName ?? patient.firstName;
      context.height = `${height} ${patient.attributes.heightUnit ?? ''}`;
      context.allergiesMedicine =
        patient?.attributes?.attributes?.allergiesMedicine?.join(', ');
      context.otherRelevantAllergies =
        patient?.attributes?.attributes?.otherRelevantAllergies?.join(', ');
      context.recreationalDrugUse =
        patient?.attributes?.attributes?.recreationalDrugUse?.join(', ');
      context.symptoms = patient?.attributes?.attributes?.symptoms?.join(', ');
      context.allergiesAdhesiveTape = patient?.attributes?.attributes
        ?.allergiesAdhesiveTape
        ? 'Yes'
        : 'No';
      context.allergiesBeeStings = patient?.attributes?.attributes
        ?.allergiesBeeStings
        ? 'Yes'
        : 'No';
      context.allergiesIodine = patient?.attributes?.attributes?.allergiesIodine
        ? 'Yes'
        : 'No';
      context.allergiesLatex = patient?.attributes?.attributes?.allergiesLatex
        ? 'Yes'
        : 'No';
      context.allergiesLidocane = patient?.attributes?.attributes
        ?.allergiesLidocane
        ? 'Yes'
        : 'No';
      context.anesthesiaComplications = patient?.attributes?.attributes
        ?.anesthesiaComplications
        ? 'Yes'
        : 'No';
      context.historyOfColdSores = patient?.attributes?.attributes
        ?.historyOfColdSores
        ? 'Yes'
        : 'No';
      context.nursing = patient?.attributes?.attributes?.nursing ? 'Yes' : 'No';
      context.planningPregnancy = patient?.attributes?.attributes
        ?.planningPregnancy
        ? 'Yes'
        : 'No';
      context.pregnant = patient?.attributes?.attributes?.pregnant
        ? 'Yes'
        : 'No';
      context.sex = sex;
      context.medicationDosage = patient?.attributes?.attributes
        ?.medication_dosage
        ? `<table><tbody><tr><th>Medication</th><th>Last Taken</th><th>Dosage</th><th>Frequency</th></tr>${patient.attributes.attributes.medication_dosage
            ?.map(
              (medication) =>
                `<tr><td>${medication?.medication}</td><td>${medication?.lastTaken}</td><td>${medication?.dosage}</td><td>${medication?.frequency}</td></tr>`
            )
            .join('')}</tbody></table>`
        : '';
      context.pastSurgeries = patient?.attributes?.attributes?.pastSurgeries
        ? `<table><tbody><tr><th>Name</th><th>Surgery Date</th></tr>${patient.attributes.attributes.pastSurgeries
            ?.map(
              (surgery) =>
                `<tr><td>${surgery?.name}</td><td>${surgery?.surgeryDate}</td></tr>`
            )
            .join('')}</tbody></table>`
        : '';
      context.familyHistory =
        patient?.attributes?.attributes?.familyHistory?.join(', ');
      context.medicalHistory =
        patient?.attributes?.attributes?.medicalHistory?.join(', ');
      context.gender = gender;
      context.weight = `${weight} lbs`;
      context.dateOfBirth = patient.attributes.dob ?? 'No DoB on record';
      context.dob = patient.attributes.dob ?? 'No DoB on record';
      context.age = patient.attributes.dob
        ? dayjs().diff(dayjs(patient.attributes.dob), 'year').toString()
        : 'Set DOB to calculate';
      if (patient?.attributes?.attributes?.pregnant?.entities) {
        context.isPregnant =
          patient?.attributes?.attributes?.pregnant?.entities?.[0]?.value !==
          'false'
            ? 'Yes'
            : 'No';
      } else {
        context.isPregnant = patient?.attributes?.attributes?.pregnant
          ? 'Yes'
          : 'No';
      }
      if (patient?.attributes?.attributes?.nursing?.entities) {
        context.isNursing =
          patient?.attributes?.attributes?.nursing?.entities?.[0]?.value !==
          'false'
            ? 'Yes'
            : 'No';
      } else {
        context.isNursing = patient?.attributes?.attributes?.nursing
          ? 'Yes'
          : 'No';
      }

      const heightInInches =
        typeof height === 'string' && height?.includes('"')
          ? heightToInches(height)
          : height;

      context.BMI =
        height && weight && heightInInches
          ? `${
              calculateBmi(
                heightToMeters(
                  heightInInches,
                  patient.attributes?.heightUnit || 'in'
                ),
                lbsToKilograms(weight)
              ).bmi
            } (${
              calculateBmi(
                heightToMeters(
                  heightInInches,
                  patient.attributes?.heightUnit || 'in'
                ),
                lbsToKilograms(weight)
              ).category
            })`
          : 'Need height and weight to calculate BMI';
    }

    if (note) {
      context.author = `${note.author?.firstName} ${note.author?.lastName}`;
    }

    if (appointment) {
      context.providerName = `${appointment.provider?.firstName} ${appointment.provider?.lastName}`;
      context.appointmentDate = dayjs(appointment.startTime).format('lll');
      context.appointmentServices = appointment?.services
        ?.map((s) => s?.service?.name)
        .join(', ');
    }

    if (flowsheets) {
      for (const flowsheet of flowsheets ?? []) {
        for (const parameter of flowsheet.rawData.parameters) {
          const parameterTitle = parameter.parameter.title;
          const parameterUnit = parameter.parameter.unit;
          const { patientRows } = flowsheet.rawData;
          const parameterData = patientRows
            ?.map((row) =>
              row.data.find(
                (data) => data.parameter.apiKey === parameter.parameter.apiKey
              )
            )
            .filter(
              (data): data is NonNullable<typeof data> => data !== undefined
            );

          const firstValue = parameterUnit
            ? `${
                parameterData[parameterData.length - 1]?.value
              } ${parameterUnit}`
            : parameterData[parameterData.length - 1]?.value;
          const latestValue = parameterUnit
            ? `${parameterData[0]?.value} ${parameterUnit}`
            : parameterData[0]?.value;

          context[`${flowsheet.title}.${parameterTitle}.firstValue`] =
            firstValue;
          context[`${flowsheet.title}.${parameterTitle}.lastValue`] =
            latestValue;
        }
      }
    }
  } catch (error) {
    console.error(error);
  }
  return context;
}

export function useGenerateMergeTagContext({
  patientId,
  appointmentId,
  note,
}: UseMergeTagContextProps): UseGenerateMergeTagContext {
  const [context, setContext] = useState<MergeTagContext | undefined>();

  const { flowsheets = [], isLoading: flowsheetsLoading } =
    usePatientFlowsheets({
      patientId,
    });

  const { data: patientData, loading } = useGetOnePatientQuery({
    variables: {
      id: patientId,
    },
    skip: !patientId,
  });
  const { getValue } = usePatientAttributes({
    patient: patientData?.patient_by_pk ?? { attributes: {} },
  });
  const appointmentQuery = useGetAppointmentQuery({
    variables: {
      id: appointmentId,
    },
    skip: !appointmentId,
  });

  useEffect(() => {
    if (
      patientId &&
      appointmentId &&
      patientData?.patient_by_pk &&
      appointmentQuery?.data?.appointment_by_pk &&
      !flowsheetsLoading
    ) {
      setContext(
        buildContext(
          patientData?.patient_by_pk,
          appointmentQuery?.data?.appointment_by_pk,
          getValue,
          flowsheets,
          note
        )
      );
    } else if (patientId && patientData?.patient_by_pk) {
      setContext(
        buildContext(
          patientData?.patient_by_pk,
          undefined,
          getValue,
          flowsheets,
          note
        )
      );
    }
  }, [
    patientData,
    appointmentQuery,
    loading,
    patientId,
    appointmentId,
    flowsheetsLoading,
  ]);

  return {
    context,
    contextReady:
      !loading && !appointmentQuery.loading && context !== undefined,
  };
}

export default useGenerateMergeTagContext;
