import { LockIcon, UnlockIcon } from '@chakra-ui/icons';
import { HStack, Button, Tag, TagLabel, TagLeftIcon } from '@chakra-ui/react';
import { useStores } from '@webapp/state-models';
import { useMemo } from 'react';
import { useUpdateNoteByPkMutation } from '@webapp/graphql';
import toast from 'react-hot-toast';

/* eslint-disable-next-line */
export interface NoteLockStatusProps {
  note: NoteFieldsFragment;
  saveNote?: ({ signatureId }: { signatureId?: string | undefined }) => void;
}

export function NoteLockStatus({ note, saveNote }: NoteLockStatusProps) {
  const { user } = useStores();

  const [updateNote] = useUpdateNoteByPkMutation({
    refetchQueries: ['GetOneNote'],
    onCompleted: () => {
      toast.success('Note lock status updated.');
    },
  });

  function unlockNote() {
    updateNote({
      variables: {
        id: note.id,
        set: {
          isLocked: false,
        },
      },
    });
  }

  function lockNote() {
    updateNote({
      variables: {
        id: note.id,
        set: {
          isLocked: true,
        },
      },
    }).then(() => {
      saveNote?.({});
    });
  }

  const canUnlock = useMemo(() => {
    if (user?.role === 'owner') {
      return true;
    }
    const signingProviderIds = note?.noteSignatures.map(
      (signature) => signature.signature?.provider?.id
    );
    return [
      ...(signingProviderIds ?? []),

      note?.assignedToProvider?.id,
    ].includes(user?.providerId);
  }, [note?.id]);

  if (!note) {
    return null;
  }

  return (
    <HStack>
      <Tag variant="subtle" colorScheme={note?.isLocked ? 'red' : 'teal'}>
        <TagLeftIcon
          boxSize="12px"
          as={note?.isLocked ? LockIcon : UnlockIcon}
        />
        <TagLabel minW="124px">
          Note is {note?.isLocked ? 'locked' : 'unlocked'}
        </TagLabel>
      </Tag>

      {note?.isLocked && canUnlock && (
        <Button
          leftIcon={<UnlockIcon />}
          variant="outline"
          colorScheme="red"
          onClick={unlockNote}
        >
          Unlock Note
        </Button>
      )}
      {!note?.isLocked && canUnlock && (
        <Button
          leftIcon={<UnlockIcon />}
          variant="outline"
          colorScheme="teal"
          onClick={lockNote}
        >
          Lock Note
        </Button>
      )}
    </HStack>
  );
}

export default NoteLockStatus;
