import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateRewardCouponsMutationVariables = Types.Exact<{
  where: Types.RewardCoupon_Bool_Exp;
  objects: Array<Types.RewardCoupon_Insert_Input> | Types.RewardCoupon_Insert_Input;
}>;


export type UpdateRewardCouponsMutation = { __typename?: 'mutation_root', delete_rewardCoupon?: { __typename?: 'rewardCoupon_mutation_response', affected_rows: number } | null | undefined, insert_rewardCoupon?: { __typename?: 'rewardCoupon_mutation_response', affected_rows: number } | null | undefined };


export const UpdateRewardCouponsDocument = gql`
    mutation updateRewardCoupons($where: rewardCoupon_bool_exp!, $objects: [rewardCoupon_insert_input!]!) {
  delete_rewardCoupon(where: $where) {
    affected_rows
  }
  insert_rewardCoupon(objects: $objects) {
    affected_rows
  }
}
    `;
export type UpdateRewardCouponsMutationFn = Apollo.MutationFunction<UpdateRewardCouponsMutation, UpdateRewardCouponsMutationVariables>;

/**
 * __useUpdateRewardCouponsMutation__
 *
 * To run a mutation, you first call `useUpdateRewardCouponsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRewardCouponsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRewardCouponsMutation, { data, loading, error }] = useUpdateRewardCouponsMutation({
 *   variables: {
 *      where: // value for 'where'
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpdateRewardCouponsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRewardCouponsMutation, UpdateRewardCouponsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRewardCouponsMutation, UpdateRewardCouponsMutationVariables>(UpdateRewardCouponsDocument, options);
      }
export type UpdateRewardCouponsMutationHookResult = ReturnType<typeof useUpdateRewardCouponsMutation>;
export type UpdateRewardCouponsMutationResult = Apollo.MutationResult<UpdateRewardCouponsMutation>;
export type UpdateRewardCouponsMutationOptions = Apollo.BaseMutationOptions<UpdateRewardCouponsMutation, UpdateRewardCouponsMutationVariables>;