import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetRewardProgramEnrollmentByPatientIdQueryVariables = Types.Exact<{
  patientId: Types.Scalars['uuid'];
}>;


export type GetRewardProgramEnrollmentByPatientIdQuery = { __typename?: 'query_root', patient?: { __typename?: 'patient', rewardProgramEnrollments: Array<{ __typename?: 'rewardProgramEnrollment', id: any, createdAt: any, referralCode: string, rewardProgramBalance?: { __typename?: 'rewardProgramBalance', balance?: any | null | undefined } | null | undefined }>, patientRewardLedgers: Array<{ __typename?: 'patientRewardLedger', id: any, date: any, points: number, transactionType: Types.PatientRewardLedgerTransactionType_Enum, description?: string | null | undefined }>, patientReferrals: Array<{ __typename?: 'patientReferral', id: any, createdAt: any, status: Types.PatientReferralStatus_Enum, appointment: { __typename?: 'appointment', id: any }, referredPatient: { __typename?: 'patient', patientWorkspaces: Array<{ __typename?: 'patientWorkspace', patientId: any, firstName?: string | null | undefined, lastName?: string | null | undefined }> } }> } | null | undefined };


export const GetRewardProgramEnrollmentByPatientIdDocument = gql`
    query getRewardProgramEnrollmentByPatientId($patientId: uuid!) {
  patient: patient_by_pk(id: $patientId) {
    rewardProgramEnrollments {
      id
      createdAt
      referralCode
      rewardProgramBalance {
        balance
      }
    }
    patientRewardLedgers {
      id
      date
      points
      transactionType
      description
    }
    patientReferrals {
      id
      createdAt
      status
      appointment {
        id
      }
      referredPatient {
        patientWorkspaces {
          patientId
          firstName
          lastName
        }
      }
    }
  }
}
    `;

/**
 * __useGetRewardProgramEnrollmentByPatientIdQuery__
 *
 * To run a query within a React component, call `useGetRewardProgramEnrollmentByPatientIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRewardProgramEnrollmentByPatientIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRewardProgramEnrollmentByPatientIdQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useGetRewardProgramEnrollmentByPatientIdQuery(baseOptions: Apollo.QueryHookOptions<GetRewardProgramEnrollmentByPatientIdQuery, GetRewardProgramEnrollmentByPatientIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRewardProgramEnrollmentByPatientIdQuery, GetRewardProgramEnrollmentByPatientIdQueryVariables>(GetRewardProgramEnrollmentByPatientIdDocument, options);
      }
export function useGetRewardProgramEnrollmentByPatientIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRewardProgramEnrollmentByPatientIdQuery, GetRewardProgramEnrollmentByPatientIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRewardProgramEnrollmentByPatientIdQuery, GetRewardProgramEnrollmentByPatientIdQueryVariables>(GetRewardProgramEnrollmentByPatientIdDocument, options);
        }
export type GetRewardProgramEnrollmentByPatientIdQueryHookResult = ReturnType<typeof useGetRewardProgramEnrollmentByPatientIdQuery>;
export type GetRewardProgramEnrollmentByPatientIdLazyQueryHookResult = ReturnType<typeof useGetRewardProgramEnrollmentByPatientIdLazyQuery>;
export type GetRewardProgramEnrollmentByPatientIdQueryResult = Apollo.QueryResult<GetRewardProgramEnrollmentByPatientIdQuery, GetRewardProgramEnrollmentByPatientIdQueryVariables>;