import { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, chakra, Icon } from '@chakra-ui/react';
import { MdCancel, MdSave } from 'react-icons/md';
import { IoMdTrash } from 'react-icons/io';
import toast from 'react-hot-toast';
import FormInputV2 from '../FormInputV2';
import { FormInputProps } from '../FormInputV2/types';

/* eslint-disable-next-line */
export interface CellEditorProps {
  inputProps: FormInputProps;
  isPopup?: boolean;
  onDelete?: (params: any) => void;
  value: any;
}

export const CellEditor = forwardRef(
  (
    {
      inputProps,
      isPopup = false,
      onDelete,
      value: valueFromProps,
      ...props
    }: CellEditorProps,
    ref
  ) => {
    const [value, setValue] = useState<any>(valueFromProps);

    useImperativeHandle(ref, () => ({
      getValue() {
        if (value && value !== 0) {
          return value;
        }

        toast.error('Value is required');

        return valueFromProps;
      },

      isCancelBeforeStart() {
        return false;
      },
    }));

    function handleChange(newValue: any) {
      setValue(newValue);
    }

    if (isPopup) {
      return (
        <chakra.div
          background="white"
          borderRadius="6px"
          boxShadow="0px 3px 12px 0px rgba(0, 0, 0, 0.11)"
          display="grid"
          padding="12px 24px"
        >
          <FormInputV2
            label="Edit Cell"
            onChange={handleChange}
            uncontrolled
            value={value}
            {...inputProps}
          />
          <chakra.div
            display="grid"
            gridColumnGap="2px"
            gridTemplateColumns="repeat(3, max-content)"
            justifyContent="end"
            padding="8px 0"
          >
            <Button
              color="gray.500"
              onClick={() => (props as any).api.stopEditing(false)}
              _hover={{
                color: 'red.500',
              }}
            >
              <Icon as={MdCancel} />
            </Button>
            {onDelete && (props as any).data[(props as any).colDef.field] && (
              <Button
                color="gray.500"
                onClick={() => {
                  onDelete(props);
                }}
                type="submit"
                _hover={{
                  color: 'red.500',
                }}
              >
                <Icon as={IoMdTrash} />
              </Button>
            )}
            <Button
              color="gray.500"
              data-testid="cell-editor-save"
              onClick={() => (props as any).api.stopEditing(false)}
              type="submit"
              _hover={{
                color: 'teal.500',
              }}
            >
              <Icon as={MdSave} />
            </Button>
          </chakra.div>
        </chakra.div>
      );
    }

    return (
      <chakra.div alignItems="center" display="grid" height="100%">
        <FormInputV2
          {...inputProps}
          onChange={handleChange}
          uncontrolled
          value={value}
        />
      </chakra.div>
    );
  }
);

export default CellEditor;
