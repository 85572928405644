import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type PrescriptionDetailFieldsFragment = { __typename?: 'prescriptionDetail', id: any, displayName: string, quantity: string, directions: string, dispenseUnitDescription: string, daysSupply: number, drugClassification?: string | null | undefined, refills: string, strength?: string | null | undefined, writtenDate: any, patient: { __typename?: 'patient', id: any, attributes: { __typename?: 'PatientAttributes', firstName?: string | null | undefined, lastName?: string | null | undefined } }, provider?: { __typename?: 'provider', id: any, firstName: string, lastName: string } | null | undefined, location: { __typename?: 'location', id: any, name: string } };

export const PrescriptionDetailFieldsFragmentDoc = gql`
    fragment PrescriptionDetailFields on prescriptionDetail {
  id
  displayName
  quantity
  directions
  dispenseUnitDescription
  daysSupply
  drugClassification
  refills
  strength
  writtenDate
  patient {
    id
    attributes {
      firstName
      lastName
    }
  }
  provider {
    id
    firstName
    lastName
  }
  location {
    id
    name
  }
}
    `;