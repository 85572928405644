import './save-and-sign.module.css';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Stack,
  HStack,
} from '@chakra-ui/react';
import { RefObject, useEffect, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { useFormContext } from 'react-hook-form';
import {
  Signature,
  StorageType,
  useGetProviderSignatureLazyQuery,
  useGetProvidersQuery,
  useInsertSignatureMutation,
  Note_Insert_Input,
} from '@webapp/graphql';
import { useStores } from '@webapp/state-models';
import { useUploadToS3 } from '@webapp/hooks';
import toast from 'react-hot-toast';
import { FaFileSignature, FaSave, FaSign } from 'react-icons/fa';
import { saveSignatureAndConvertToFile } from '../signature-util';

/* eslint-disable-next-line */
export interface SaveAndSignProps {
  title?: string;
  buttonTitle?: string;
  onSigned: (signature: Partial<Note_Insert_Input>) => void;
}

export function SaveAndSign({
  title,
  buttonTitle,
  onSigned,
}: SaveAndSignProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { setValue, handleSubmit, getValues } = useFormContext();

  const [providerSignature, setProviderSignature] = useState<Signature | null>(
    null
  );

  const { uploadToS3 } = useUploadToS3();
  const [progress, setProgress] = useState(0);
  const [insertSignature] = useInsertSignatureMutation();

  const [getProviderSignature, { called, loading }] =
    useGetProviderSignatureLazyQuery({
      onCompleted: (data) => {
        setProviderSignature(data?.signature[0]);
      },
    });

  const { user, workspace } = useStores();

  const [providerId, setProviderId] = useState<string | null>(null);

  useEffect(() => {
    if (providerSignature && providerSignature?.file?.url) {
      signatureRef.current?.off();
      loadExistingSignature(signatureRef, providerSignature?.file?.url);
    }
  }, [providerSignature, isOpen]);

  useGetProvidersQuery({
    variables: {
      where: {
        userId: {
          _eq: user?.id,
        },
      },
    },
    onCompleted(data) {
      if (data?.provider?.length) {
        setProviderId(data.provider[0].id);
        getProviderSignature({
          variables: {
            providerId: data.provider[0].id,
          },
        });
      }
    },
  });

  const signatureRef = useRef<SignatureCanvas>(null);

  function blobToBase64(blob: Blob): Promise<unknown> {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  async function loadExistingSignature(
    sigRef: RefObject<SignatureCanvas>,
    signatureUrl: string
  ) {
    const fetchResponse = await fetch(signatureUrl);
    const responseBuffer = await fetchResponse.blob();
    const base64 = await blobToBase64(responseBuffer);
    sigRef.current?.fromDataURL(base64 as string);
  }

  function clearSignature() {
    signatureRef.current?.clear();
    setProviderSignature(null);
  }

  async function save(e: React.MouseEvent<HTMLButtonElement>) {
    handleSubmit(onSigned)(e);
    onClose();
  }

  async function signAndSave(e: React.MouseEvent<HTMLButtonElement>) {
    let signature;

    if (!providerId) {
      handleSubmit(onSigned)(e);
      onClose();
      return;
    }

    if (!providerSignature) {
      const fileName = `${providerId}-${new Date().getTime()}.png`;

      const file = await saveSignatureAndConvertToFile(
        signatureRef.current,
        fileName
      );

      const filePath = await uploadToS3({
        fileType: file.type,
        fileContents: file,
        filePath: file.name,
        storageType: StorageType.Document,
        onProgress: setProgress,
      });
      const { data } = await insertSignature({
        variables: {
          signature: {
            providerId,
            filePath,
          },
        },
      });
      signature = data?.insert_signature?.returning[0];
    } else {
      signature = providerSignature;
    }
    if (signature) {
      setValue('signatureId', signature.id);
      handleSubmit(onSigned)(e);
      onClose();
    } else {
      toast.error(`No signature!`);
    }
  }

  return (
    <>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        spacing={{ base: 2, md: 4 }}
        width="auto"
      >
        <Button
          leftIcon={<FaSave />}
          onClick={save}
          width={{ base: '100%', md: 'auto' }}
        >
          Save
        </Button>
        {providerId && (
          <Button
            leftIcon={<FaFileSignature />}
            colorScheme="teal"
            onClick={onOpen}
            width={{ base: '100%', md: 'auto' }}
          >
            {buttonTitle || 'Save & Sign'}
          </Button>
        )}
      </Stack>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title || 'Please sign to continue'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack
              borderColor="gray.300"
              borderWidth={1}
              bg="gray.50"
              p={4}
              w={'510px'}
              h={'210px'}
              justifyContent="center"
              alignItems={'center'}
            >
              {/*  @ts-ignore-error React 18 */}
              <SignatureCanvas
                ref={signatureRef}
                canvasProps={{
                  width: 500,
                  height: 200,
                  className: 'sigCanvas',
                }}
              />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <HStack>
              <Button onClick={clearSignature}>Clear Signature</Button>
              <Button
                colorScheme="teal"
                mr={3}
                type="button"
                onClick={signAndSave}
              >
                Sign
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default SaveAndSign;
