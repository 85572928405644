import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateRewardsMutationVariables = Types.Exact<{
  where: Types.Reward_Bool_Exp;
  objects: Array<Types.Reward_Insert_Input> | Types.Reward_Insert_Input;
}>;


export type UpdateRewardsMutation = { __typename?: 'mutation_root', delete_reward?: { __typename?: 'reward_mutation_response', affected_rows: number } | null | undefined, insert_reward?: { __typename?: 'reward_mutation_response', affected_rows: number } | null | undefined };


export const UpdateRewardsDocument = gql`
    mutation updateRewards($where: reward_bool_exp!, $objects: [reward_insert_input!]!) {
  delete_reward(where: $where) {
    affected_rows
  }
  insert_reward(objects: $objects) {
    affected_rows
  }
}
    `;
export type UpdateRewardsMutationFn = Apollo.MutationFunction<UpdateRewardsMutation, UpdateRewardsMutationVariables>;

/**
 * __useUpdateRewardsMutation__
 *
 * To run a mutation, you first call `useUpdateRewardsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRewardsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRewardsMutation, { data, loading, error }] = useUpdateRewardsMutation({
 *   variables: {
 *      where: // value for 'where'
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpdateRewardsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRewardsMutation, UpdateRewardsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRewardsMutation, UpdateRewardsMutationVariables>(UpdateRewardsDocument, options);
      }
export type UpdateRewardsMutationHookResult = ReturnType<typeof useUpdateRewardsMutation>;
export type UpdateRewardsMutationResult = Apollo.MutationResult<UpdateRewardsMutation>;
export type UpdateRewardsMutationOptions = Apollo.BaseMutationOptions<UpdateRewardsMutation, UpdateRewardsMutationVariables>;