import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetUsersWithTimesheetQueryVariables = Types.Exact<{
  workspaceId: Types.Scalars['uuid'];
}>;


export type GetUsersWithTimesheetQuery = { __typename?: 'query_root', user: Array<{ __typename?: 'user', id: any, firstName: string, lastName: string }> };


export const GetUsersWithTimesheetDocument = gql`
    query GetUsersWithTimesheet($workspaceId: uuid!) {
  user(
    where: {timesheets_aggregate: {count: {predicate: {_gt: 0}}}, userWorkspace: {workspaceId: {_eq: $workspaceId}}}
  ) {
    id
    firstName
    lastName
  }
}
    `;

/**
 * __useGetUsersWithTimesheetQuery__
 *
 * To run a query within a React component, call `useGetUsersWithTimesheetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersWithTimesheetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersWithTimesheetQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useGetUsersWithTimesheetQuery(baseOptions: Apollo.QueryHookOptions<GetUsersWithTimesheetQuery, GetUsersWithTimesheetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersWithTimesheetQuery, GetUsersWithTimesheetQueryVariables>(GetUsersWithTimesheetDocument, options);
      }
export function useGetUsersWithTimesheetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersWithTimesheetQuery, GetUsersWithTimesheetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersWithTimesheetQuery, GetUsersWithTimesheetQueryVariables>(GetUsersWithTimesheetDocument, options);
        }
export type GetUsersWithTimesheetQueryHookResult = ReturnType<typeof useGetUsersWithTimesheetQuery>;
export type GetUsersWithTimesheetLazyQueryHookResult = ReturnType<typeof useGetUsersWithTimesheetLazyQuery>;
export type GetUsersWithTimesheetQueryResult = Apollo.QueryResult<GetUsersWithTimesheetQuery, GetUsersWithTimesheetQueryVariables>;