import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { TimesheetUserFieldsFragmentDoc } from '../../fragments/timesheetUserFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetMostRecentTimesheetEntryQueryVariables = Types.Exact<{
  userId: Types.Scalars['uuid'];
}>;


export type GetMostRecentTimesheetEntryQuery = { __typename?: 'query_root', timesheet: Array<{ __typename?: 'timesheet', approvedBy?: any | null | undefined, clockInTime: any, clockOutTime?: any | null | undefined, id: any, isApproved: boolean, userId: any, userNote?: string | null | undefined }> };


export const GetMostRecentTimesheetEntryDocument = gql`
    query GetMostRecentTimesheetEntry($userId: uuid!) {
  timesheet(
    where: {userId: {_eq: $userId}, clockOutTime: {_is_null: true}}
    order_by: {createdAt: desc}
    limit: 1
  ) {
    ...TimesheetUserFields
  }
}
    ${TimesheetUserFieldsFragmentDoc}`;

/**
 * __useGetMostRecentTimesheetEntryQuery__
 *
 * To run a query within a React component, call `useGetMostRecentTimesheetEntryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMostRecentTimesheetEntryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMostRecentTimesheetEntryQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetMostRecentTimesheetEntryQuery(baseOptions: Apollo.QueryHookOptions<GetMostRecentTimesheetEntryQuery, GetMostRecentTimesheetEntryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMostRecentTimesheetEntryQuery, GetMostRecentTimesheetEntryQueryVariables>(GetMostRecentTimesheetEntryDocument, options);
      }
export function useGetMostRecentTimesheetEntryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMostRecentTimesheetEntryQuery, GetMostRecentTimesheetEntryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMostRecentTimesheetEntryQuery, GetMostRecentTimesheetEntryQueryVariables>(GetMostRecentTimesheetEntryDocument, options);
        }
export type GetMostRecentTimesheetEntryQueryHookResult = ReturnType<typeof useGetMostRecentTimesheetEntryQuery>;
export type GetMostRecentTimesheetEntryLazyQueryHookResult = ReturnType<typeof useGetMostRecentTimesheetEntryLazyQuery>;
export type GetMostRecentTimesheetEntryQueryResult = Apollo.QueryResult<GetMostRecentTimesheetEntryQuery, GetMostRecentTimesheetEntryQueryVariables>;