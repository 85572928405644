import { useEffect, useState } from 'react';
import { useStores } from '@webapp/state-models';
import {
  Box,
  Button,
  Text,
  VStack,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react';
import { configuredDayjs as dayjs } from '@webapp/util-time';
import { getIpAddress } from '@webapp/utils';
import {
  useGetMostRecentTimesheetEntryQuery,
  useCreateTimesheetEntryMutation,
  useUpdateTimesheetEntryMutation,
} from '@webapp/graphql';

export function TimeClock() {
  const { calendarTimeZone, user, workspace } = useStores();
  const [timesheetEntryId, setTimesheetEntryId] = useState<string | null>(null);
  const [clockedIn, setClockedIn] = useState(false);
  const [clockInTime, setClockInTime] = useState<string | null>(null);
  const [userNote, setUserNote] = useState('');
  const [ipAddress, setIpAddress] = useState<string>('');

  useEffect(() => {
    getIpAddress().then((ip) => setIpAddress(ip));
  }, []);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data: timesheetEntryData } = useGetMostRecentTimesheetEntryQuery({
    variables: {
      userId: user?.id,
    },
  });

  const [createTimesheetEntry, { loading: isCreating }] =
    useCreateTimesheetEntryMutation({
      onCompleted: (data) => {
        const now = dayjs().tz(calendarTimeZone);
        setClockedIn(true);
        setTimesheetEntryId(data?.insert_timesheet_one?.id);
        setClockInTime(now.format('hh:mm A z'));
      },
    });

  const [updateTimesheetEntry, { loading: isUpdating }] =
    useUpdateTimesheetEntryMutation({
      onCompleted: () => {
        setClockedIn(false);
        onClose();
      },
    });

  useEffect(() => {
    if (timesheetEntryData?.timesheet && timesheetEntryData?.timesheet.length) {
      setClockedIn(true);
      setTimesheetEntryId(timesheetEntryData.timesheet[0].id);
      setClockInTime(
        dayjs(timesheetEntryData.timesheet[0].clockInTime)
          .tz(calendarTimeZone)
          .format('hh:mm A z')
      );
    }
  }, [timesheetEntryData]);

  const handleClockIn = () => {
    const now = dayjs().tz(calendarTimeZone);
    createTimesheetEntry({
      variables: {
        object: {
          userId: user?.id,
          workspaceId: workspace?.id,
          clockInTime: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        },
      },
    });
  };

  const handleClockOut = () => {
    if (timesheetEntryId) {
      updateTimesheetEntry({
        variables: {
          id: timesheetEntryId,
          clockOutTime: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          userNote,
        },
      });
    }
  };

  const renderCurrentTime = () => {
    return dayjs().tz(calendarTimeZone).format('hh:mm A z');
  };

  return (
    <>
      <Box bg="white" p={4} borderRadius="md" width="250px" textAlign="center">
        <VStack spacing={4} color="text.100">
          {!clockedIn ? (
            <Box>
              <Text fontSize="lg">Start</Text>
              <Text fontSize="lg" fontWeight="bold">
                {renderCurrentTime()}
              </Text>
            </Box>
          ) : (
            <Box>
              <Text fontSize="sm" mb={6}>
                Started @ {clockInTime}
              </Text>
              <Text fontSize="lg">End</Text>
              <Text fontSize="lg" fontWeight="bold">
                {renderCurrentTime()}
              </Text>
            </Box>
          )}

          <Button
            data-testId="clock-action-button"
            colorScheme={clockedIn ? 'red' : 'teal'}
            isLoading={isCreating}
            onClick={clockedIn ? onOpen : handleClockIn}
            width="100%"
          >
            {clockedIn ? 'Clock Out' : 'Clock In'}
          </Button>
        </VStack>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Clock Out</ModalHeader>
          <ModalBody>
            <Text>Are you sure you want to clock out?</Text>
            <Textarea
              data-testId="timesheet-user-note-input"
              placeholder="Add any notes here..."
              value={userNote}
              onChange={(e) => setUserNote(e.target.value)}
              mt={4}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" onClick={onClose}>
              Cancel
            </Button>
            <Button
              data-testId="clock-out-button"
              colorScheme="teal"
              isLoading={isUpdating}
              onClick={handleClockOut}
              ml={3}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default TimeClock;
