import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteTimeSheetEntriesMutationVariables = Types.Exact<{
  where: Types.Timesheet_Bool_Exp;
}>;


export type DeleteTimeSheetEntriesMutation = { __typename?: 'mutation_root', delete_timesheet?: { __typename?: 'timesheet_mutation_response', affected_rows: number } | null | undefined };


export const DeleteTimeSheetEntriesDocument = gql`
    mutation DeleteTimeSheetEntries($where: timesheet_bool_exp!) {
  delete_timesheet(where: $where) {
    affected_rows
  }
}
    `;
export type DeleteTimeSheetEntriesMutationFn = Apollo.MutationFunction<DeleteTimeSheetEntriesMutation, DeleteTimeSheetEntriesMutationVariables>;

/**
 * __useDeleteTimeSheetEntriesMutation__
 *
 * To run a mutation, you first call `useDeleteTimeSheetEntriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTimeSheetEntriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTimeSheetEntriesMutation, { data, loading, error }] = useDeleteTimeSheetEntriesMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteTimeSheetEntriesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTimeSheetEntriesMutation, DeleteTimeSheetEntriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTimeSheetEntriesMutation, DeleteTimeSheetEntriesMutationVariables>(DeleteTimeSheetEntriesDocument, options);
      }
export type DeleteTimeSheetEntriesMutationHookResult = ReturnType<typeof useDeleteTimeSheetEntriesMutation>;
export type DeleteTimeSheetEntriesMutationResult = Apollo.MutationResult<DeleteTimeSheetEntriesMutation>;
export type DeleteTimeSheetEntriesMutationOptions = Apollo.BaseMutationOptions<DeleteTimeSheetEntriesMutation, DeleteTimeSheetEntriesMutationVariables>;