import React, { useEffect, useState, useCallback } from 'react';
import { Box, HStack, IconButton, Select } from '@chakra-ui/react';
import './main.css';
import TextStyle from '@tiptap/extension-text-style';
import { Color } from '@tiptap/extension-color';
import ListItem from '@tiptap/extension-list-item';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import HardBreak from '@tiptap/extension-hard-break';
import FontSize from 'tiptap-extension-font-size';

import { AiOutlineOrderedList } from 'react-icons/ai';
import { BsFileBreak } from 'react-icons/bs';
import {
  FaBold,
  FaItalic,
  FaUnderline,
  FaStrikethrough,
  FaUndo,
  FaRedo,
} from 'react-icons/fa';
import { GoTasklist } from 'react-icons/go';
import { MdOutlineFormatListBulleted } from 'react-icons/md';

const MenuBar = ({ editor }) => {
  if (!editor) {
    return null;
  }

  const standardButtons = [
    {
      label: 'bold',
      onClick: () => editor.chain().focus().toggleBold().run(),
      icon: <FaBold />,
    },
    {
      label: 'italic',
      onClick: () => editor.chain().focus().toggleItalic().run(),
      icon: <FaItalic />,
    },
    {
      label: 'underline',
      onClick: () => editor.chain().focus().toggleUnderline().run(),
      icon: <FaUnderline />,
    },
    {
      label: 'strike',
      onClick: () => editor.chain().focus().toggleStrike().run(),
      icon: <FaStrikethrough />,
    },
    {
      label: 'tasklist',
      onClick: () => editor.chain().focus().toggleTaskList().run(),
      icon: <GoTasklist />,
    },
    {
      label: 'bulletList',
      onClick: () => editor.chain().focus().toggleBulletList().run(),
      icon: <MdOutlineFormatListBulleted />,
    },
    {
      label: 'orderedList',
      onClick: () => editor.chain().focus().toggleOrderedList().run(),
      icon: <AiOutlineOrderedList />,
    },
    {
      label: 'undo',
      onClick: () => editor.chain().focus().undo().run(),
      icon: <FaUndo />,
    },
    {
      label: 'redo',
      onClick: () => editor.chain().focus().redo().run(),
      icon: <FaRedo />,
    },
    {
      label: 'hard-break',
      onClick: () => editor.chain().focus().setHardBreak().run(),
      icon: <BsFileBreak />,
    },
    {
      label: 'fontSize',
      component: (
        <Select
          size="xs"
          width="auto"
          onChange={(e) =>
            editor.chain().focus().setFontSize(e.target.value).run()
          }
          value={editor.getAttributes('textStyle').fontSize || '16px'}
        >
          {['12px', '14px', '16px', '18px', '20px', '24px', '30px'].map(
            (size) => (
              <option key={size} value={size}>
                {size}
              </option>
            )
          )}
        </Select>
      ),
    },
  ];

  return (
    <HStack spacing={1}>
      {standardButtons.map(({ label, onClick, icon, component }) =>
        component ? (
          <Box key={label}>{component}</Box>
        ) : (
          <IconButton
            key={label}
            aria-label={label}
            onClick={onClick}
            icon={icon}
            size="xs"
            variant={editor.isActive(label) ? 'solid' : 'outline'}
            colorScheme={editor.isActive(label) ? 'teal' : 'grey'}
          />
        )
      )}
      <input
        type="color"
        onInput={(event) =>
          editor.chain().focus().setColor(event.target.value).run()
        }
        value={editor.getAttributes('textStyle').color}
        data-testid="setColor"
      />
    </HStack>
  );
};

const extensions = [
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  TextStyle.configure({ types: [ListItem.name] }),
  StarterKit.configure({
    bulletList: {},
    orderedList: {},
  }),
  FontSize,
  HardBreak.extend({
    addKeyboardShortcuts() {
      return {
        Enter: ({ editor }) => {
          if (
            editor.isActive('bulletList') ||
            editor.isActive('orderedList') ||
            editor.isActive('taskList')
          ) {
            return false;
          }
          return this.editor.commands.setHardBreak();
        },
      };
    },
  }),
];

/* eslint-disable-next-line */
export interface TextEditorProps {
  readOnly?: boolean;
  defaultValue?: string;
  onChange?: (value: string) => void;
}

export function TextEditor({
  readOnly = false,
  defaultValue = '',
  onChange,
}: TextEditorProps) {
  const editor = useEditor({
    extensions,
    content: defaultValue,
    editable: !readOnly,
    onUpdate: ({ editor }) => {
      const newValue = editor.getHTML();
      onChange?.(newValue);
    },
  });

  useEffect(() => {
    if (editor && defaultValue !== editor.getHTML()) {
      editor.commands.setContent(defaultValue);
    }
  }, [editor, defaultValue]);

  return (
    <Box p={2} border="1px solid #e2e8f0">
      <MenuBar editor={editor} />
      <EditorContent editor={editor} />
    </Box>
  );
}

export default TextEditor;
