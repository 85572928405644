import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { TimesheetAdminFieldsFragmentDoc } from '../../fragments/timesheetAdminFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetAllTimesheetEntriesQueryVariables = Types.Exact<{
  where: Types.Timesheet_Bool_Exp;
}>;


export type GetAllTimesheetEntriesQuery = { __typename?: 'query_root', timesheet: Array<{ __typename?: 'timesheet', adminNote?: string | null | undefined, approvedBy?: any | null | undefined, clockInTime: any, clockOutTime?: any | null | undefined, flaggedForReview: boolean, id: any, isApproved: boolean, userNote?: string | null | undefined, approvingUser?: { __typename?: 'user', id: any, firstName: string, lastName: string } | null | undefined, user: { __typename?: 'user', id: any, firstName: string, lastName: string } }> };


export const GetAllTimesheetEntriesDocument = gql`
    query GetAllTimesheetEntries($where: timesheet_bool_exp!) {
  timesheet(where: $where, order_by: {clockInTime: desc}) {
    ...TimesheetAdminFields
  }
}
    ${TimesheetAdminFieldsFragmentDoc}`;

/**
 * __useGetAllTimesheetEntriesQuery__
 *
 * To run a query within a React component, call `useGetAllTimesheetEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTimesheetEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTimesheetEntriesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAllTimesheetEntriesQuery(baseOptions: Apollo.QueryHookOptions<GetAllTimesheetEntriesQuery, GetAllTimesheetEntriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllTimesheetEntriesQuery, GetAllTimesheetEntriesQueryVariables>(GetAllTimesheetEntriesDocument, options);
      }
export function useGetAllTimesheetEntriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllTimesheetEntriesQuery, GetAllTimesheetEntriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllTimesheetEntriesQuery, GetAllTimesheetEntriesQueryVariables>(GetAllTimesheetEntriesDocument, options);
        }
export type GetAllTimesheetEntriesQueryHookResult = ReturnType<typeof useGetAllTimesheetEntriesQuery>;
export type GetAllTimesheetEntriesLazyQueryHookResult = ReturnType<typeof useGetAllTimesheetEntriesLazyQuery>;
export type GetAllTimesheetEntriesQueryResult = Apollo.QueryResult<GetAllTimesheetEntriesQuery, GetAllTimesheetEntriesQueryVariables>;