import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RedeemRewardPointsMutationVariables = Types.Exact<{
  patientId: Types.Scalars['uuid'];
  rewardId?: Types.Maybe<Types.Scalars['uuid']>;
  rewardCouponId?: Types.Maybe<Types.Scalars['uuid']>;
}>;


export type RedeemRewardPointsMutation = { __typename?: 'mutation_root', redeemRewardPoints?: { __typename?: 'RedeemRewardPointsOutput', success: boolean } | null | undefined };


export const RedeemRewardPointsDocument = gql`
    mutation redeemRewardPoints($patientId: uuid!, $rewardId: uuid, $rewardCouponId: uuid) {
  redeemRewardPoints(
    patientId: $patientId
    rewardId: $rewardId
    rewardCouponId: $rewardCouponId
  ) {
    success
  }
}
    `;
export type RedeemRewardPointsMutationFn = Apollo.MutationFunction<RedeemRewardPointsMutation, RedeemRewardPointsMutationVariables>;

/**
 * __useRedeemRewardPointsMutation__
 *
 * To run a mutation, you first call `useRedeemRewardPointsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedeemRewardPointsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redeemRewardPointsMutation, { data, loading, error }] = useRedeemRewardPointsMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      rewardId: // value for 'rewardId'
 *      rewardCouponId: // value for 'rewardCouponId'
 *   },
 * });
 */
export function useRedeemRewardPointsMutation(baseOptions?: Apollo.MutationHookOptions<RedeemRewardPointsMutation, RedeemRewardPointsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RedeemRewardPointsMutation, RedeemRewardPointsMutationVariables>(RedeemRewardPointsDocument, options);
      }
export type RedeemRewardPointsMutationHookResult = ReturnType<typeof useRedeemRewardPointsMutation>;
export type RedeemRewardPointsMutationResult = Apollo.MutationResult<RedeemRewardPointsMutation>;
export type RedeemRewardPointsMutationOptions = Apollo.BaseMutationOptions<RedeemRewardPointsMutation, RedeemRewardPointsMutationVariables>;