/* eslint-disable max-classes-per-file */
import { createElement } from 'react';
import {
  PropertyGridEditorCollection,
  localization,
} from 'survey-creator-react';
import { ElementFactory, Question, Serializer, SvgRegistry } from 'survey-core';
import {
  SurveyQuestionElementBase,
  ReactQuestionFactory,
} from 'survey-react-ui';
import { Switch } from '@chakra-ui/react';
import unidecode from 'unidecode';
import { TextEditor } from '@webapp/ui';
import Icon from './icon.svg?raw';

const CUSTOM_TYPE = 'rich-text-editor';

// A model for the new question type
export class RichTextModel extends Question {
  getType() {
    return CUSTOM_TYPE;
  }
}

// Register `RichTextModel` as a model for the `rich-text-editor` type
export function registerRichTextEditor() {
  ElementFactory.Instance.registerElement(
    CUSTOM_TYPE,
    (name) => new RichTextModel(name)
  );
}

// Specify display names for the question type and its properties
const locale = localization.getLocale('');
locale.qt[CUSTOM_TYPE] = 'RichTextEditor';

// Register an SVG icon for the question type
SvgRegistry.registerIconFromSvg(CUSTOM_TYPE, Icon);

// Add question type metadata for further serialization into JSON
Serializer.addClass(
  CUSTOM_TYPE,
  [],
  () => new RichTextModel('RichTextEditor'),
  'question'
);

// A class that renders questions of the new type in the UI
export class RichTextEditor extends SurveyQuestionElementBase {
  constructor(props) {
    super(props);
    this.state = { value: this.question.value };
  }

  get question() {
    return this.questionBase;
  }

  get value() {
    return this.question.value;
  }

  // Support the read-only and design modes
  get style() {
    return this.question.getPropertyValue('readOnly') ||
      this.question.isDesignMode
      ? undefined
      : { pointerEvents: 'none' };
  }

  renderElement() {
    return (
      <div>
        <TextEditor
          defaultValue={this.question.value}
          readOnly={false}
          onChange={(t) => {
            console.log(unidecode(t), 'HTML');
            this.question.value = unidecode(t);
          }}
        />
      </div>
    );
  }
}

// Register `RichTextEditor` as a class that renders `rich-text-editor` questions
ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, (props) =>
  createElement(RichTextEditor, props)
);
PropertyGridEditorCollection.register({
  fit(prop) {
    if (prop.name.toLowerCase().includes('code')) return false;

    return prop.type === 'rich-text' || prop.type === 'html';
  },
  getJSON() {
    return {
      type: CUSTOM_TYPE,
    };
  },
});
