import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { TimesheetUserFieldsFragmentDoc } from '../../fragments/timesheetUserFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetUserTimesheetEntryQueryVariables = Types.Exact<{
  userId: Types.Scalars['uuid'];
}>;


export type GetUserTimesheetEntryQuery = { __typename?: 'query_root', timesheet: Array<{ __typename?: 'timesheet', approvedBy?: any | null | undefined, clockInTime: any, clockOutTime?: any | null | undefined, id: any, isApproved: boolean, userId: any, userNote?: string | null | undefined }> };


export const GetUserTimesheetEntryDocument = gql`
    query GetUserTimesheetEntry($userId: uuid!) {
  timesheet(where: {userId: {_eq: $userId}}, order_by: {clockInTime: desc}) {
    ...TimesheetUserFields
  }
}
    ${TimesheetUserFieldsFragmentDoc}`;

/**
 * __useGetUserTimesheetEntryQuery__
 *
 * To run a query within a React component, call `useGetUserTimesheetEntryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserTimesheetEntryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserTimesheetEntryQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserTimesheetEntryQuery(baseOptions: Apollo.QueryHookOptions<GetUserTimesheetEntryQuery, GetUserTimesheetEntryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserTimesheetEntryQuery, GetUserTimesheetEntryQueryVariables>(GetUserTimesheetEntryDocument, options);
      }
export function useGetUserTimesheetEntryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserTimesheetEntryQuery, GetUserTimesheetEntryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserTimesheetEntryQuery, GetUserTimesheetEntryQueryVariables>(GetUserTimesheetEntryDocument, options);
        }
export type GetUserTimesheetEntryQueryHookResult = ReturnType<typeof useGetUserTimesheetEntryQuery>;
export type GetUserTimesheetEntryLazyQueryHookResult = ReturnType<typeof useGetUserTimesheetEntryLazyQuery>;
export type GetUserTimesheetEntryQueryResult = Apollo.QueryResult<GetUserTimesheetEntryQuery, GetUserTimesheetEntryQueryVariables>;