import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UseDisclosureProps,
} from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

type PromptModalProps = {
  bodyText?: string;
  colorScheme?: string;
  confirmText?: string;
  denyText?: string;
  headerText?: string;
  isLoading?: boolean;
  onCancel?: () => void;
  onConfirm: () => void;
  shouldCloseOnConfirm?: boolean;
  withCancelButton?: boolean;
  withCloseButton?: boolean;
  withOverlay?: boolean;
} & Required<Pick<UseDisclosureProps, 'isOpen' | 'onClose'>>;

export function PromptModal({
  bodyText,
  children,
  colorScheme = 'red',
  confirmText = 'Submit',
  denyText = 'Cancel',
  headerText = 'Confirm Action',
  isLoading = false,
  isOpen,
  onCancel,
  onClose,
  onConfirm,
  shouldCloseOnConfirm = false,
  withCancelButton = true,
  withOverlay = true,
}: PropsWithChildren<PromptModalProps>) {
  const hasChildren = Boolean(children);

  const handleConfirm = () => {
    onConfirm();

    if (shouldCloseOnConfirm) {
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {withOverlay && <ModalOverlay />}
      <ModalContent overflow="hidden">
        {headerText && (
          <ModalHeader
            background={`${colorScheme}.500`}
            color={colorScheme ? 'white' : 'inherit'}
          >
            {headerText}
          </ModalHeader>
        )}
        <ModalBody>
          {!bodyText && !hasChildren && (
            <Text>Are you sure you want to complete this action?</Text>
          )}
          {bodyText && <Text>{bodyText}</Text>}
          {hasChildren && children}
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            {withCancelButton && (
              <Button
                disabled={isLoading}
                onClick={
                  onCancel
                    ? () => {
                        onCancel();
                        onClose();
                      }
                    : onClose
                }
              >
                {denyText}
              </Button>
            )}
            <Button
              data-testid="prompt-modal-submit"
              colorScheme={colorScheme}
              isLoading={isLoading}
              onClick={handleConfirm}
            >
              {confirmText}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default PromptModal;
