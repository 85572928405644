import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type TimesheetUserFieldsFragment = { __typename?: 'timesheet', approvedBy?: any | null | undefined, clockInTime: any, clockOutTime?: any | null | undefined, id: any, isApproved: boolean, userId: any, userNote?: string | null | undefined };

export const TimesheetUserFieldsFragmentDoc = gql`
    fragment TimesheetUserFields on timesheet {
  approvedBy
  clockInTime
  clockOutTime
  id
  isApproved
  userId
  userNote
}
    `;