/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-case-declarations */
/* eslint-disable camelcase */
import {
  Box,
  Button,
  chakra,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Image,
  Input,
  Select,
  Stack,
  Switch,
} from '@chakra-ui/react';
import {
  CustomFormFieldType_Enum,
  useGetCustomFormResponsesQuery,
  useDeleteCustomFormResponseMutation,
  ListAppointmentsDocument,
  useGetPinAggregateQuery,
  StorageType,
  useInsertSignatureMutation,
  useInsertCustomFormFieldResponseMutation,
  useCompleteFormMutation,
  useInsertCustomFormResponseMutation,
  useGetOnePatientQuery,
} from '@webapp/graphql';
import { CustomForm as MSTCustomForm, useStores } from '@webapp/state-models';
import {
  FormInput,
  PageHeader,
  PlacesInput,
  PopulatedCustomFormMultipleSelect,
  PopulatedCustomFormSignatureCanvas,
  PopulatedCustomFormSingleSelect,
  TextEditor,
  SegmentedControlInput,
} from '@webapp/ui';
import {
  processCustomFormValue,
  SurveyJSCustomForm,
} from '@webapp/ui-composites';
import { convertLegacyPatientAttributesToSurveyData } from '@webapp/utils';
import { camelCase, groupBy } from 'lodash';
import { useCallback, useRef, useState, useEffect } from 'react';
import {
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import toast from 'react-hot-toast';
import { FiTrash } from 'react-icons/fi';
import { useNavigate, useParams } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';
import { phone } from 'phone';
import { validate as validateEmail } from 'email-validator';
import {
  CustomFormNonNullable,
  FormValuesType,
  ValidationOptions,
} from './types';
import DeleteModal from '../delete-modal';
import VerifyPinModal from '../verify-pin-modal';
import './populated-custom-form.css';
import { HiPlus } from 'react-icons/hi';
import { nanoid } from 'nanoid';
import SignatureCanvas from 'react-signature-canvas';
import { useUploadToS3 } from '@webapp/hooks';
import { observer } from 'mobx-react-lite';
import { SingleField } from '@webapp/types';
import { saveSignatureAndConvertToFile } from '../signature-util';
import './SurveyJSCustomForm.print.module.scss';

interface PopulatedCustomFormProps {
  defaultValues: FormValuesType;
  form: CustomFormNonNullable | MSTCustomForm;
  isPreview?: boolean;
  printing?: boolean;
  setStateForm?: (form: any) => void;
}

export const PopulatedCustomForm = observer(
  ({
    defaultValues,
    form,
    isPreview,
    printing,
    setStateForm,
  }: PopulatedCustomFormProps): JSX.Element => {
    const {
      workspace,
      setOutstandingForms,
      outstandingForms,
      outstandingConsents,
      ui,
    } = useStores();

    const [deleteResponse] = useDeleteCustomFormResponseMutation();
    const [showVerifyModal, setShowVerifyModal] = useState(false);
    const { uploadToS3 } = useUploadToS3();

    const signatureRefs = useRef<{ [x: string]: SignatureCanvas }>({});

    const [insertSignature] = useInsertSignatureMutation();

    const { formId: id, patientId, appointmentId } = useParams();

    const { data: patientData, loading: patientLoading } =
      useGetOnePatientQuery({
        variables: {
          id: patientId,
        },
      });

    const navigate = useNavigate();
    const [deleteOpen, setDeleteOpen] = useState(false);
    const { data: pinAggregateData } = useGetPinAggregateQuery({
      variables: {
        where: {
          workspaceId: {
            _eq: workspace?.id,
          },
        },
      },
    });

    const onOpen = (): void => {
      setDeleteOpen(true);
    };

    const onClose = (): void => {
      setDeleteOpen(false);
    };

    const onVerifyOpen = (): void => {
      setShowVerifyModal(true);
    };

    const verifyOrGoNext = (): void => {
      if (pinAggregateData?.pin_aggregate.aggregate?.count) {
        onVerifyOpen();
      } else {
        onVerify();
      }
    };

    const onVerify = (): void => {
      ui.setGlobalPinLock(false);
      navigate(`/patients/${patientId}/appointments/${appointmentId}`);
    };

    const onVerifyClose = (): void => {
      setShowVerifyModal(false);
    };

    useEffect(() => {
      if (pinAggregateData?.pin_aggregate.aggregate?.count) {
        ui.setGlobalPinLock(true);
      }
    }, [pinAggregateData]);

    const methods = useForm({
      mode: 'onChange',
      reValidateMode: 'onChange',
      criteriaMode: 'firstError',
      shouldFocusError: true,
      defaultValues,
      values: defaultValues,
    });

    const refetchQueries = [
      {
        query: ListAppointmentsDocument,
        variables: {
          where: {
            patientId: {
              _eq: patientId,
            },
          },
        },
      },
    ];

    const [fetchedSignatures, setFetchedSignatures] = useState<{
      [x: string]: boolean;
    }>({});

    const { register, handleSubmit, setValue, getValues } = methods;

    const { data: responseData } = useGetCustomFormResponsesQuery({
      variables: {
        where: {
          appointmentId: {
            _eq: appointmentId,
          },
          customFormId: {
            _eq: id,
          },
        },
      },
    });

    const constructFormId = (field: { id: string; label: string }): string =>
      `${field.id}:${camelCase(field.label)}`;

    const existingResponse = responseData?.customFormResponse?.[0];

    const handleDelete = async () => {
      if (!existingResponse) {
        toast.error('No response found');
        return;
      }

      deleteResponse({
        variables: {
          where: {
            id: { _eq: existingResponse?.id },
          },
        },
        onCompleted: () => {
          toast.success('Response deleted');
          navigate(`/patients/${patientId}/appointments`);
        },
        onError: (error) => {
          toast.error(error.message);
          onClose();
        },
        refetchQueries,
      });
    };

    const [completeForm] = useCompleteFormMutation();
    const [insertCustomFormFieldResponse] =
      useInsertCustomFormFieldResponseMutation();
    const [progress, setProgress] = useState(0);

    const { title, rows, allFieldsRequired } = form;

    const resolveValidation = useCallback(
      (label: string, validation?: string): ValidationOptions => {
        let parsedValidation = validation ? JSON.parse(validation) : {};

        if (parsedValidation.email) {
          parsedValidation = {
            validate: {
              validEmail: (v: string) =>
                validateEmail(v) || 'Invalid email address',
            },
          };
        }

        if (parsedValidation.phone) {
          parsedValidation = {
            validate: {
              validPhoneNumber: (v: string) =>
                phone(v).isValid || 'Invalid phone number',
            },
          };
        }

        if (allFieldsRequired) {
          parsedValidation.required = `${label} is required`;
        }

        return parsedValidation;
      },
      []
    );

    const returnCorrectInput = useCallback(
      (field: SingleField): JSX.Element | null => {
        const fieldId = constructFormId(field);

        const resolvedValidation = resolveValidation(
          field.label,
          field.validation
        );

        switch (field.type) {
          case CustomFormFieldType_Enum.MultipleSelect:
            const [checks] = getValues([fieldId]);

            return (
              <PopulatedCustomFormMultipleSelect
                field={field}
                fieldId={fieldId}
                defaultValue={checks}
                setValue={setValue}
              />
            );
          case CustomFormFieldType_Enum.MultipleSelectWithImage:
            const [checksWithImage] = getValues([fieldId]);

            return (
              <Stack>
                <Image maxH={60} w="fit-content" src={field.photo?.file?.url} />
                <PopulatedCustomFormMultipleSelect
                  field={field}
                  fieldId={fieldId}
                  defaultValue={checksWithImage}
                  setValue={setValue}
                />
              </Stack>
            );
          case CustomFormFieldType_Enum.SingleSelect:
            const [radio] = getValues([fieldId]);

            return (
              <PopulatedCustomFormSingleSelect
                field={field}
                defaultValue={radio}
                fieldId={fieldId}
                setValue={setValue}
              />
            );
          case CustomFormFieldType_Enum.SingleSelectWithImage:
            const [imageRadio] = getValues([fieldId]);

            return (
              <Stack>
                <Image maxH={60} w="fit-content" src={field.photo?.file?.url} />
                <PopulatedCustomFormSingleSelect
                  field={field}
                  defaultValue={imageRadio}
                  fieldId={fieldId}
                  setValue={setValue}
                />
              </Stack>
            );
          case CustomFormFieldType_Enum.DropdownSelect:
            const [dropdown] = getValues([fieldId]);

            return (
              <Select
                {...register(fieldId)}
                defaultValue={dropdown}
                minW="16vw"
                textAlign="center"
              >
                {field.options.map((option) => (
                  <option key={option.id} value={option.value}>
                    {option.value}
                  </option>
                ))}
              </Select>
            );
          case CustomFormFieldType_Enum.TextInput:
            return (
              <Input
                {...register(fieldId, resolvedValidation)}
                minW="16vw"
                textAlign="center"
              />
            );
          case CustomFormFieldType_Enum.Address:
            return (
              <Box minW="20vw">
                <PlacesInput name={fieldId} />
              </Box>
            );
          case CustomFormFieldType_Enum.Date:
            return <FormInput type="flexible-datepicker" name={fieldId} />;
          case CustomFormFieldType_Enum.YesNo:
            const [yesNo] = getValues([fieldId]);

            return (
              <SegmentedControlInput
                {...register(fieldId)}
                defaultValue={yesNo ?? 'false'}
                onChange={(value) => setValue(fieldId, value)}
                items={[
                  { value: 'true', label: 'Yes' },
                  { value: 'false', label: 'No' },
                ]}
              />
            );
          case CustomFormFieldType_Enum.CreatableSelect:
            const [select] = getValues([fieldId]);

            return (
              <CreatableSelect
                defaultValue={select}
                isMulti
                onChange={(value) =>
                  setValue(
                    fieldId,
                    value.map((v) => v.value)
                  )
                }
                options={field.options.map(({ value }) => ({
                  label: value,
                  value,
                }))}
              />
            );
          case CustomFormFieldType_Enum.CreatableSingleSelect:
            const [creatableSingelSelect] = getValues([fieldId]);

            return (
              <CreatableSelect
                defaultValue={creatableSingelSelect}
                onChange={(value) => setValue(fieldId, value.value)}
                options={field.options.map(({ value }) => ({
                  label: value,
                  value,
                }))}
              />
            );
          case CustomFormFieldType_Enum.Signature:
            const [existingSignature] = getValues([fieldId]);

            return (
              <PopulatedCustomFormSignatureCanvas
                defaultValue={existingSignature}
                fieldId={fieldId}
                signatureRefs={signatureRefs}
                fetchedSignatures={fetchedSignatures}
                setFetchedSignatures={setFetchedSignatures}
              />
            );

          default:
            return null;
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [form.id, signatureRefs]
    );

    type PopulatedFormInput = {
      [x: string]: unknown;
    };

    const verifySignatures = (
      allSignatureRefs: [string, SignatureCanvas][]
    ): void => {
      for (const [_, singleSignatureRef] of allSignatureRefs) {
        const signatureFieldIsEmpty = singleSignatureRef.isEmpty();

        if (allFieldsRequired && signatureFieldIsEmpty) {
          throw new Error('Signature field is empty');
        }
      }
    };

    const [insertCustomFormResponse] = useInsertCustomFormResponseMutation({
      refetchQueries: ['GetOnePatient'],
    });
    if (patientLoading) {
      return <div>Loading...</div>;
    }

    const { attributes, ...restOfAttributes } =
      patientData?.patient_by_pk?.attributes ?? {};

    const lastFormResponse =
      responseData?.customFormResponse?.[0]?.surveyJSData;

    // The purpose of the below code is to merge the last form response with the patient attributes, giving priority to the patient attributes as they get updated on every form response
    for (const [key, value] of Object.entries(lastFormResponse ?? {})) {
      if (attributes && attributes[key]) {
        lastFormResponse[key] = attributes?.[key];
      }

      if (
        lastFormResponse &&
        lastFormResponse[key] &&
        typeof lastFormResponse[key] === 'object'
      ) {
        for (const [key2, value2] of Object.entries(
          lastFormResponse?.[key] ?? {}
        )) {
          if (attributes && attributes[key2]) {
            lastFormResponse[key][key2] = attributes?.[key2];
          }
        }
      }
    }

    const surveyJSData = {
      ...convertLegacyPatientAttributesToSurveyData(
        patientData?.patient_by_pk?.attributes?.attributes
      ),
      ...lastFormResponse,
      ...restOfAttributes,
    };

    if (patientData?.patient_by_pk?.patientAddresses?.length) {
      const defaultAddress = patientData.patient_by_pk.patientAddresses.find(
        (address) => address.isDefault
      );
      const { address: addressToUse } =
        defaultAddress || patientData.patient_by_pk.patientAddresses[0];

      surveyJSData.addressLine1 = addressToUse.addressLine1;
      surveyJSData.addressLine2 = addressToUse.addressLine2;
      surveyJSData.city = addressToUse.city;
      surveyJSData.state = addressToUse.state;
      surveyJSData.zip = addressToUse.zipCode;
    }

    if (surveyJSData.height && typeof surveyJSData.height === 'number') {
      const feet = Math.floor(surveyJSData.height / 12);
      const inches = surveyJSData.height % 12;
      surveyJSData.height = `${feet}'${inches}"`;
    }

    const onSubmitSurveyJS = async (
      newSurveyJSData: Record<string, any>
    ): Promise<void> => {
      try {
        await insertCustomFormResponse({
          variables: {
            customFormResponse: {
              customFormId: id,
              patientId,
              appointmentId,
              surveyJSData: newSurveyJSData,
            },
          },
        });
        handleOutstandingForms();
      } catch (error) {
        toast.error((error as Error).message);
      }
    };

    const onSubmit: SubmitHandler<PopulatedFormInput> = async (
      data
    ): Promise<void> => {
      try {
        const allSignatureRefs = Object.entries(signatureRefs.current);

        verifySignatures(allSignatureRefs);

        const fieldResponseKeys = Object.keys(data).filter(
          (key) => !key.includes('replicated')
        );

        const replicatedKeys = Object.keys(data).filter(
          (key) => key.includes('replicated') && Boolean(data[key])
        );

        const groupedRows = groupBy(
          replicatedKeys,
          (value) => value.split(':')[1]
        );

        const customFormResponse = await completeForm({
          variables: {
            appointmentId,
            customFormResponse: {
              customFormId: id,
              patientId,
              appointmentId,
              fieldResponses: {
                data: fieldResponseKeys.map((key) => ({
                  customFormFieldId: key.split(':')[0],
                  value: processCustomFormValue(data[key]),
                })),
              },
              replicatedRows: {
                data: Object.entries(groupedRows).map(([key, value]) => ({
                  index: parseInt(key, 10),
                  apiFieldGroupKey: value[0].split(':')[2],
                  groupLabel: value[0].split(':')[3],
                  fields: {
                    data: value.map((item) => ({
                      customFormFieldId: item.split(':')[0],
                      value: data[item] as string,
                    })),
                  },
                })),
              },
            },
          },
          refetchQueries,
        });

        const customFormResponseId =
          customFormResponse.data?.insert_customFormResponse?.returning[0]?.id;

        for (const [aFieldId, singleSignatureRef] of allSignatureRefs) {
          const fileName = `${aFieldId}.png`;

          const file = await saveSignatureAndConvertToFile(
            singleSignatureRef,
            fileName
          );

          const filePath = await uploadToS3({
            fileType: file.type,
            fileContents: file,
            filePath: file.name,
            storageType: StorageType.Document,
            onProgress: setProgress,
            randomizeFileName: true,
          });

          const insertVariables = {
            signature: {
              patientId,
              filePath,
            },
          };

          const { data: signatureData } = await insertSignature({
            variables: insertVariables,
          });

          const newSignature = signatureData?.insert_signature?.returning[0];

          const customFormFieldId = aFieldId.split(':')[0];

          await insertCustomFormFieldResponse({
            variables: {
              customFormFieldResponse: {
                customFormFieldId,
                customFormResponseId,
                value: newSignature?.id,
              },
            },
          });
        }

        toast.success('Form submitted successfully');

        handleOutstandingForms();
      } catch (error) {
        toast.error((error as Error).message);
      }
    };

    const handleOutstandingForms = () => {
      if (outstandingForms.length > 1) {
        const newOutstandFormsArray = [...outstandingForms] as string[];

        const indexOfCompletedForm = newOutstandFormsArray.indexOf(form.id);

        newOutstandFormsArray.splice(indexOfCompletedForm, 1);

        setOutstandingForms(newOutstandFormsArray);

        const nextForm = newOutstandFormsArray[0];

        navigate(
          `/patients/${patientId}/appointments/${appointmentId}/forms/${nextForm}`
        );
      } else {
        setOutstandingForms([]);

        if (outstandingConsents.length) {
          const nextConsent = outstandingConsents[0];

          navigate(
            `/patients/${patientId}/appointments/${appointmentId}/consents/${nextConsent}`
          );
        } else {
          verifyOrGoNext();
        }
      }
    };

    const onError: SubmitErrorHandler<FormInput> = (errors): void => {
      const [firstError] = Object.values(errors) as { message: string }[];

      if (firstError?.message) {
        toast.error(firstError.message);
      }
    };

    const addRow = (row: any, index: number): void => {
      const tempForm = { ...form };

      let newRowIndex = index + 1;

      const groupLabel = row.fields
        .map((field: any) => field.label)
        .join(' and ');

      const apiFieldGroupKey = row.fields
        .map((field: any) => camelCase(field.label))
        .join('_');

      const { rows: tempFormRows } = tempForm;

      for (let i = index + 1; i < tempFormRows.length; i++) {
        if (!tempFormRows[i].replicated) {
          break;
        }

        newRowIndex = i + 1;
      }

      const updatedRow = {
        id: nanoid(),
        index: newRowIndex,
        replicated: true,
        fields: row.fields.map((field: any) => ({
          ...field,
          id: `${field.id}:${newRowIndex}:${apiFieldGroupKey}:${groupLabel}:replicated`,
        })),
      };

      tempForm.rows.splice(newRowIndex, 0, updatedRow);

      if (setStateForm) {
        setStateForm(tempForm);
      }
    };

    const removeRow = (index: number): void => {
      const tempForm = { ...form };

      tempForm.rows.splice(index, 1);

      if (setStateForm) {
        setStateForm(tempForm);
      }
    };

    const height = isPreview ? '90vh' : '95vh';

    const isSurveyJSForm = !!form.surveyJSJSON;

    return (
      <Stack
        id="populated-custom-form"
        w="full"
        overflow="visible"
        position="relative"
        height={printing ? 'auto' : height} // Change this line
        minHeight={printing ? '100%' : height}
      >
        <style jsx global>{`
          @media print {
            .sd-root-modern.sd-progress--pages.sd-root--readonly.sd-root-modern--full-container {
              overflow: visible !important;
            }
            .chakra-text {
              overflow: visible !important;
              page-break-inside: avoid !important;
            }
            .sd-body.sd-body--responsive {
              padding: 4px !important;
            }
          }
        `}</style>
        {isSurveyJSForm ? (
          <SurveyJSCustomForm
            key={form.id}
            surveyJSJSON={form.surveyJSJSON}
            data={surveyJSData}
            onSubmit={onSubmitSurveyJS}
            printing={printing}
            patient={patientData?.patient_by_pk}
            form={form}
            appointmentId={appointmentId}
            presentationContext={'public'}
          />
        ) : (
          <>
            <PageHeader title={title} backButton={isPreview}></PageHeader>

            <FormProvider {...methods}>
              <chakra.form onSubmit={handleSubmit(onSubmit, onError)} mt={0}>
                <Stack
                  className={
                    isPreview
                      ? 'populated-form-question-stack-preview'
                      : 'populated-form-question-stack'
                  }
                  borderRadius={10}
                  p={10}
                  pb={44}
                  display={printing ? 'block' : 'flex'}
                  h={printing ? 'full' : height}
                  spacing={6}
                  overflowY={'auto'}
                  sx={{
                    '&::-webkit-scrollbar': {
                      width: '0px',
                      borderRadius: '8px',
                      backgroundColor: `rgba(0, 0, 0, 0.05)`,
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: `rgba(0, 0, 0, 0.05)`,
                    },
                  }}
                >
                  {rows.map((row: any, index) => (
                    <>
                      <HStack key={row.id} width="full" spacing={2}>
                        {row.fields.map((field: any) => {
                          if (field.body) {
                            return (
                              <TextEditor readOnly={true} value={field.body} />
                            );
                          }

                          return (
                            <FormControl
                              key={field.id}
                              id={field.id}
                              minW="20vw"
                            >
                              <FormLabel>{field.label}</FormLabel>
                              {returnCorrectInput(field)}
                            </FormControl>
                          );
                        })}
                        {row.replicable && (
                          <IconButton
                            mt="30px !important"
                            aria-label="add-row"
                            variant="outline"
                            icon={<HiPlus />}
                            onClick={() => addRow(row, index)}
                          />
                        )}
                        {row.replicated && (
                          <IconButton
                            mt="30px !important"
                            colorScheme="red"
                            aria-label="remove-row"
                            variant="outline"
                            icon={<FiTrash />}
                            onClick={() => removeRow(index)}
                          />
                        )}
                      </HStack>
                      {/* {index !== 0 && index % 8 === 0 && (
                    <div className="pagebreak"> </div>
                  )} */}
                    </>
                  ))}
                </Stack>
                {!printing && (
                  <Box
                    bg="white"
                    width="100%"
                    position={'fixed'}
                    bottom={0}
                    zIndex={10}
                  >
                    <Divider />
                    <Flex justifyContent="space-between" p={4}>
                      <Button onClick={verifyOrGoNext} disabled={isPreview}>
                        Back
                      </Button>
                      <HStack>
                        {!isPreview && Boolean(existingResponse) && (
                          <IconButton
                            aria-label="delete-field"
                            variant="outline"
                            colorScheme="red"
                            icon={<FiTrash />}
                            onClick={onOpen}
                          />
                        )}
                        <Button
                          type="submit"
                          colorScheme="teal"
                          disabled={isPreview}
                        >
                          Finish
                        </Button>
                      </HStack>
                    </Flex>
                  </Box>
                )}
              </chakra.form>
            </FormProvider>
          </>
        )}
        <VerifyPinModal
          isOpen={showVerifyModal}
          onClose={onVerifyClose}
          onVerify={onVerify}
        />
        <DeleteModal
          itemName="response"
          isOpen={deleteOpen}
          onClose={onClose}
          handleDelete={handleDelete}
        />
      </Stack>
    );
  }
);

export default PopulatedCustomForm;
