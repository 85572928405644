import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { TimesheetUserFieldsFragmentDoc } from '../../fragments/timesheetUserFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateTimesheetEntryMutationVariables = Types.Exact<{
  object: Types.Timesheet_Insert_Input;
}>;


export type CreateTimesheetEntryMutation = { __typename?: 'mutation_root', insert_timesheet_one?: { __typename?: 'timesheet', approvedBy?: any | null | undefined, clockInTime: any, clockOutTime?: any | null | undefined, id: any, isApproved: boolean, userId: any, userNote?: string | null | undefined } | null | undefined };


export const CreateTimesheetEntryDocument = gql`
    mutation CreateTimesheetEntry($object: timesheet_insert_input!) {
  insert_timesheet_one(object: $object) {
    ...TimesheetUserFields
  }
}
    ${TimesheetUserFieldsFragmentDoc}`;
export type CreateTimesheetEntryMutationFn = Apollo.MutationFunction<CreateTimesheetEntryMutation, CreateTimesheetEntryMutationVariables>;

/**
 * __useCreateTimesheetEntryMutation__
 *
 * To run a mutation, you first call `useCreateTimesheetEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimesheetEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimesheetEntryMutation, { data, loading, error }] = useCreateTimesheetEntryMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useCreateTimesheetEntryMutation(baseOptions?: Apollo.MutationHookOptions<CreateTimesheetEntryMutation, CreateTimesheetEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTimesheetEntryMutation, CreateTimesheetEntryMutationVariables>(CreateTimesheetEntryDocument, options);
      }
export type CreateTimesheetEntryMutationHookResult = ReturnType<typeof useCreateTimesheetEntryMutation>;
export type CreateTimesheetEntryMutationResult = Apollo.MutationResult<CreateTimesheetEntryMutation>;
export type CreateTimesheetEntryMutationOptions = Apollo.BaseMutationOptions<CreateTimesheetEntryMutation, CreateTimesheetEntryMutationVariables>;