import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type TimesheetAdminFieldsFragment = { __typename?: 'timesheet', adminNote?: string | null | undefined, approvedBy?: any | null | undefined, clockInTime: any, clockOutTime?: any | null | undefined, flaggedForReview: boolean, id: any, isApproved: boolean, userNote?: string | null | undefined, approvingUser?: { __typename?: 'user', id: any, firstName: string, lastName: string } | null | undefined, user: { __typename?: 'user', id: any, firstName: string, lastName: string } };

export const TimesheetAdminFieldsFragmentDoc = gql`
    fragment TimesheetAdminFields on timesheet {
  adminNote
  approvedBy
  approvingUser {
    id
    firstName
    lastName
  }
  clockInTime
  clockOutTime
  flaggedForReview
  id
  isApproved
  user {
    id
    firstName
    lastName
  }
  userNote
}
    `;