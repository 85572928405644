import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { TimesheetAdminFieldsFragmentDoc } from '../../fragments/timesheetAdminFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertTimesheetEntryMutationVariables = Types.Exact<{
  object: Types.Timesheet_Insert_Input;
}>;


export type InsertTimesheetEntryMutation = { __typename?: 'mutation_root', insert_timesheet_one?: { __typename?: 'timesheet', adminNote?: string | null | undefined, approvedBy?: any | null | undefined, clockInTime: any, clockOutTime?: any | null | undefined, flaggedForReview: boolean, id: any, isApproved: boolean, userNote?: string | null | undefined, approvingUser?: { __typename?: 'user', id: any, firstName: string, lastName: string } | null | undefined, user: { __typename?: 'user', id: any, firstName: string, lastName: string } } | null | undefined };


export const InsertTimesheetEntryDocument = gql`
    mutation InsertTimesheetEntry($object: timesheet_insert_input!) {
  insert_timesheet_one(object: $object) {
    ...TimesheetAdminFields
  }
}
    ${TimesheetAdminFieldsFragmentDoc}`;
export type InsertTimesheetEntryMutationFn = Apollo.MutationFunction<InsertTimesheetEntryMutation, InsertTimesheetEntryMutationVariables>;

/**
 * __useInsertTimesheetEntryMutation__
 *
 * To run a mutation, you first call `useInsertTimesheetEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertTimesheetEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertTimesheetEntryMutation, { data, loading, error }] = useInsertTimesheetEntryMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertTimesheetEntryMutation(baseOptions?: Apollo.MutationHookOptions<InsertTimesheetEntryMutation, InsertTimesheetEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertTimesheetEntryMutation, InsertTimesheetEntryMutationVariables>(InsertTimesheetEntryDocument, options);
      }
export type InsertTimesheetEntryMutationHookResult = ReturnType<typeof useInsertTimesheetEntryMutation>;
export type InsertTimesheetEntryMutationResult = Apollo.MutationResult<InsertTimesheetEntryMutation>;
export type InsertTimesheetEntryMutationOptions = Apollo.BaseMutationOptions<InsertTimesheetEntryMutation, InsertTimesheetEntryMutationVariables>;