import { Box, Circle, Stack } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { NavItem } from '@webapp/ui';
import {
  BiBuoy,
  BiChat,
  BiCog,
  BiHome,
  BiPhotoAlbum,
  BiTime,
} from 'react-icons/bi';
import { Link as ReactLink, useLocation } from 'react-router-dom';
import { useFlagsmith } from 'flagsmith-react';
import {
  FaChartLine,
  FaFlask,
  FaFunnelDollar,
  FaMoneyBillAlt,
  FaPrescription,
  FaRegCalendar,
  FaRegEnvelope,
  FaRegStar,
  FaRegThumbsUp,
  FaTasks,
  FaUserFriends,
} from 'react-icons/fa';
import { VH_MINUS_HEADER } from '@webapp/webapp/util-display';
import { useStores } from '@webapp/state-models';
import { useCustomPermissions } from '@webapp/hooks';
import WorkspaceSelector from '../workspace-selector/workspace-selector';

export function MenuLinks({ minimized }: { minimized: boolean }) {
  const location = useLocation();
  const { hasFeature } = useFlagsmith();
  const { hasPermission } = useCustomPermissions();
  const { user, workspace } = useStores();
  const role = user?.userWorkspaces[0]?.role;
  const LINKS = [
    {
      canRender: true,
      pathname: '/',
      label: 'Home',
      icon: BiHome,
    },
    {
      canRender: hasFeature('patients') && role !== 'bookkeeper',
      pathname: '/patients',
      label: 'Patients',
      icon: FaUserFriends,
    },
    {
      canRender: hasFeature('prescriptions') && role !== 'bookkeeper',
      pathname: '/prescriptions',
      label: 'Prescriptions',
      icon: FaPrescription,
    },
    {
      canRender: hasFeature('schedule') && role !== 'bookkeeper',
      pathname: '/schedule',
      label: 'Schedule',
      icon: FaRegCalendar,
    },
    {
      canRender: hasFeature('timesheet') && role !== 'bookkeeper',
      pathname: '/timesheet',
      label: 'Timesheet',
      icon: BiTime,
    },
    {
      canRender: hasFeature('labs') && role !== 'bookkeeper',
      pathname: '/labs',
      label: 'Labs',
      icon: FaFlask,
    },
    {
      canRender: hasFeature('media_archive') && role !== 'bookkeeper',
      pathname: '/media',
      label: 'Media Archive',
      icon: BiPhotoAlbum,
    },
    {
      canRender: hasFeature('social_media') && role !== 'bookkeeper',
      pathname: '/social-media',
      label: 'Social Media',
      icon: FaRegThumbsUp,
    },
    {
      canRender: hasFeature('email_marketing') && role !== 'bookkeeper',
      pathname: '/email-marketing',
      label: 'Direct Marketing',
      icon: FaRegEnvelope,
    },
    {
      canRender: hasFeature('lead_list') && role !== 'bookkeeper',
      pathname: `/leads?after=${dayjs()
        .subtract(90, 'days')
        .format('YYYY-MM-DD')}&before=${dayjs()
        .add(1, 'day')
        .format('YYYY-MM-DD')}`,
      label: 'Opportunities',
      icon: FaFunnelDollar,
    },
    {
      canRender: hasFeature('online_reputation') && role !== 'bookkeeper',
      pathname: '/reviews',
      label: 'Online Reputation',
      icon: FaRegStar,
    },

    {
      canRender: hasFeature('reports') && role !== 'bookkeeper',
      pathname: '/reports/daily-snapshot',
      label: 'Reports',
      icon: FaChartLine,
    },
    {
      canRender:
        hasFeature('invoices') &&
        hasPermission('viewInvoice') &&
        role !== 'bookkeeper',
      pathname: '/invoices',
      label: 'Invoices',
      icon: FaMoneyBillAlt,
    },
    {
      canRender: hasFeature('conversations') && role !== 'bookkeeper',
      pathname: '/conversations',
      label: 'Conversations',
      icon: BiChat,
    },
    {
      canRender: hasFeature('tasks-page') && role !== 'bookkeeper',
      pathname: '/tasks',
      label: 'Tasks',
      icon: FaTasks,
    },
  ];

  const featureLinks = LINKS.filter((link) => link.canRender);
  function showHelpChat() {
    if (workspace?.organization?.organizationConfiguration?.supportEmail) {
      window.open(
        `mailto:${workspace.organization.organizationConfiguration.supportEmail}`,
        '_blank'
      );
      return;
    }
    window.Intercom('show');
  }

  return (
    <Stack
      overflowY="auto"
      height={VH_MINUS_HEADER}
      css={{
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
      sx={{
        scrollbarWidth: 'none',
      }}
    >
      <Stack spacing="8" flex="1">
        <Stack spacing="1">
          {featureLinks.map((link) => (
            <ReactLink key={`feature-link-${link.label}`} to={link.pathname}>
              <NavItem
                minimized={minimized}
                active={location.pathname === link.pathname}
                icon={<link.icon />}
                label={link.label}
              />
            </ReactLink>
          ))}
        </Stack>
      </Stack>
      <Box>
        <Stack spacing="1" justifyContent="flex-end">
          {hasFeature('workspace_selector') && (
            <WorkspaceSelector minimized={minimized} />
          )}
          <ReactLink to="/settings">
            <NavItem
              minimized={minimized}
              subtle
              icon={<BiCog />}
              label="Settings"
            />
          </ReactLink>
          <Box onClick={showHelpChat}>
            <NavItem
              subtle
              minimized={minimized}
              icon={<BiBuoy />}
              label="Help & Support"
              endElement={<Circle size="2" bg="blue.400" />}
            />
          </Box>
        </Stack>
      </Box>
    </Stack>
  );
}

export default MenuLinks;
