import { ComponentCollection, SvgRegistry, setLicenseKey } from 'survey-core';
import {
  FaBaby,
  FaBoxTissue,
  FaBriefcaseMedical,
  FaPencilAlt,
  FaPills,
  FaUser,
  FaUsers,
  FaHistory,
  FaAddressCard,
} from 'react-icons/fa';
import { renderToString } from 'react-dom/server';
import type { IconBaseProps } from 'react-icons';
import { elementsAsComponentConfiguration } from '@webapp/constants';
import uniqBy from 'lodash/uniqBy';

setLicenseKey(
  'MTMxYTY2ODMtNTQxMC00NWIzLTliMGMtZGMyN2RiMGUzMzgyOzE9MjAyNS0wMi0wOCwyPTIwMjUtMDItMDgsND0yMDI1LTAyLTA4'
);
const iconDefaults: IconBaseProps = {
  size: 24,
  color: 'gray',
};

export default function configureComponents() {
  ComponentCollection.Instance.clear();

  // Using react-dom/server to accomplish this is not ideal and adds some weight, but is easiest:
  SvgRegistry.registerIconFromSvg(
    'emergencyContact',
    renderToString(<FaAddressCard {...iconDefaults} />)
  );
  SvgRegistry.registerIconFromSvg(
    'allergyHistory',
    renderToString(<FaBoxTissue {...iconDefaults} />)
  );
  SvgRegistry.registerIconFromSvg(
    'pregnancy',
    renderToString(<FaBaby {...iconDefaults} />)
  );
  SvgRegistry.registerIconFromSvg(
    'symptoms',
    renderToString(<FaBriefcaseMedical {...iconDefaults} />)
  );
  SvgRegistry.registerIconFromSvg(
    'history',
    renderToString(<FaHistory {...iconDefaults} />)
  );
  SvgRegistry.registerIconFromSvg(
    'pregnancy',
    renderToString(<FaBaby {...iconDefaults} />)
  );
  SvgRegistry.registerIconFromSvg(
    'patientProfile',
    renderToString(<FaUser {...iconDefaults} />)
  );
  SvgRegistry.registerIconFromSvg(
    'socialHistory',
    renderToString(<FaUsers {...iconDefaults} />)
  );
  SvgRegistry.registerIconFromSvg(
    'freeResponse',
    renderToString(<FaPencilAlt {...iconDefaults} />)
  );
  SvgRegistry.registerIconFromSvg(
    'medication_dosage',
    renderToString(<FaPills {...iconDefaults} />)
  );

  uniqBy(elementsAsComponentConfiguration, 'name').forEach((element) => {
    try {
      ComponentCollection.Instance.add(element);
    } catch (error) {
      console.error(error);
    }
  });
}
